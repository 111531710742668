import React from 'react';
// import ap from '../images/app.png'
import { Link } from 'react-router-dom';
import ap from '../images/final_hands.png';
import gp from '../images/gp.png';
import br from '../images/landing_brands.png';

export default function AboutIntro() {
  return (
    <>
      <div class="introContainer">
        <div class="row fw-row">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-12 introCol">
                <div class="row">
                  <div class="col-md-12">
                    <h1 className="fwintro">
                      Work, Earn & <br></br>Learn{' '}
                    </h1>
                    <h2 className="fwsmintro">Work, Earn & Learn </h2>
                  </div>
                  <div class="col-md-12">
                    <p className="anytime">
                      India’s most gigger-friendly platform!
                    </p>
                  </div>
                  <div class="col-md-12">
                    <hr className="new5"></hr>
                  </div>
                  <div class="col-md-12">
                    <h4 className="fwh4">
                      Your trustworthy platform where you work as you like, earn
                      for your hard work and learn with exposure to upskill.
                    </h4>
                  </div>
                  {/* <div class="col-md-12 fwiconclass">
                    <p style={{ color: '#FD581F' }}>Download Now</p>
                    <Link
                      to={{
                        pathname:
                          'https://play.google.com/store/apps/details?id=com. GigPe™.parttimejobs',
                      }}
                      target="_blank"
                    >
                      <img alt="img" className="gplay" src={gp}></img>
                    </Link>
                  </div> */}
                </div>
              </div>
              <div class="col-md-12 various1">
                <div class="row introCol1">
                  <div class="col-md-12" style={{ marginLeft: '5%' }}>
                    <h3>
                      From the team who helped some amazing brands to scale!
                    </h3>
                  </div>
                  <div class="col-md-12" style={{ marginLeft: '5%' }}>
                    <img alt="brandss" className="br2" src={br}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <img className="ap1" src={ap}></img>
          </div>
        </div>
      </div>

      <div className="divContainer">
        <div className="divLeft">
          <h1 className="fwintro">
            Work, Earn & <br></br>Learn{' '}
          </h1>
          <h2 className="fwsmintro">Work, Earn & Learn </h2>
          <p className="anytime">India’s most gigger-friendly platform!</p>

          <hr className="new5"></hr>

          <h4 className="fwh4">
            Your trustworthy platform where you work as you like, earn for your
            hard work and learn with exposure to upskill. Call yourself a
            ‘Vibecheck Technologies Pvt. Ltd.’.
          </h4>
          {/* <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <p style={{ color: '#FD581F' }}>Download Now</p>
            <img alt="img" className="gplay" src={gp}></img>
          </div> */}
        </div>
        <div className="divRight">
          <div>
            <img className="ap" src={ap}></img>
          </div>
          <div className="various">
            <h3>Brands you all have helped us scale, there are many more!</h3>
            <img className="br1" src={br} />
          </div>
        </div>
      </div>
    </>
  );
}
