import React from 'react';
import { Link } from 'react-router-dom';
import page404 from '../images/pagenotfound.gif';
import { Button } from 'react-bootstrap';
export default function Handle404() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="page-not-found-div">
        <img src={page404} />
      </div>
      <div className="page-not-found-div">
        <button
          style={{
            backgroundColor: '#6543B1',
            color: 'white',
            padding: '10px',
            outline: 'none',
            border: 'none',
            marginTop: '-50px',
            marginBottom: '20px',
            borderRadius: '3px',
          }}
        >
          <Link style={{ color: 'white' }} to="/">
            Head back home
          </Link>
        </button>
      </div>
    </>
  );
}
