import React, { Component } from 'react';
import Intro from '../components/Intro';
import Buss from '../components/Buss';
import Sol from '../components/Sol';
import How from '../components/How';
import Banner from '../components/Banner';
import Slider from '../components/Slider';
import Cards1 from '../components/Cards1';

export default function Homepage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Intro />
      <Banner />
      <Buss />
      <Sol />
      <How />
      {/* <Slider/> */}
      <Cards1 />
    </div>
  );
}
