import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/Rocket.png';
import th from '../images/Thread.png';
import bl from '../images/blob.png';
import im from '../images/im.png';
// import bl from '../images/blob.png'
export default function Sol() {
  return (
    <div>
      <div className="sol-div">
        <div>
          <img className="blob" src={bl}></img>
          <img className="im" src={im}></img>
        </div>

        <div className="getintouch">
          <h1 className="getinh">Customised Solution</h1>
          <p className="getinp">
            Our talented workforce, The GigPe™ Army (also known as vibecheck
            technologiess) helps you to set up unique tailor made solutions for
            all your business needs.{' '}
          </p>
          <Link to="/formpage-a">
            <button type="submit" className="getinb">
              Let's Get in Touch
            </button>
          </Link>
        </div>

        <div className="faded" style={{ textAlign: 'end' }}>
          <p>Small opportunities are</p>
          <p>often the beginning of</p>
          <p>great achievements</p>
          <p>Connect. Collaborate. Grow</p>
        </div>
      </div>
      <img className="rocket" src={logo}></img>
      <img className="thread" src={th}></img>
    </div>
  );
}
