import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import logo from '../images/logo.png';
import Div100vh from 'react-div-100vh';

export default function Navbar() {
  const [open, setOpen] = useState(false);

  // if (open) {
  //     return (
  //             <div className="fixer">
  //                 <nav className="navbar-header-mobile">

  //                     <section style={{
  //                         display: 'flex',
  //                         flexDirection: 'row',
  //                         alignItems: 'center'
  //                     }}>
  //                         <h1 onClick={() => setOpen(!open)} className="ham"><GiHamburgerMenu /></h1>
  //                         <section className="youth" style={{ width: '100%', textAlign: 'center' }}>
  //                             <Link to="./">
  //                                 <img alt="logo" src={logo} style={{ width: '35%' }}></img>
  //                             </Link>
  //                         </section>
  //                     </section>

  //                     <div className="nav-links" style={{ display: 'flex',  transform: open ? "translateX(0px)" : "", width:"100%"}}>

  //                         <ul  style={{width:'50%'}}>
  //                             <Div100vh>
  //                             <Link to="/" onClick={() => setOpen(false)}><li>Business</li></Link>
  //                             <Link to="/find-work" onClick={() => setOpen(false)}><li>Find Work</li></Link>
  //                             <Link to="/about" onClick={() => setOpen(false)}><li>About Us</li></Link>
  //                             <Link to="/contact-us" onClick={() => setOpen(false)}><li>Contact Us</li></Link>
  //                             <Link to="/formpage-a" onClick={() => setOpen(false)}><li>Post a Project</li></Link>
  //                             </Div100vh>
  //                         </ul>

  //                         <Div100vh style={{ width: '50%', background:'rgba(0, 0, 0, 0.5)', opacity:'0.3', cursor:'pointer'}} onClick={()=>setOpen(false)}>

  //                         </Div100vh>

  //                     </div>

  // </nav>
  //             </div >
  //         )
  //     }

  return (
    <div className="fixer">
      <nav className="navbar-header">
        <section className="youth">
          <Link to="./">
            <span className={'logo'} style={{ fontSize: '37px' }}>
              GigPe™
            </span>
            {/* <img alt="logo" src={logo}></img> */}
          </Link>
        </section>

        <section>
          <li className="navbar-ul">
            {/* <ul style={{ marginTop: '15px' }}>
              <Link to="/" style={{ paddingTop: '3px' }}>
                Business
              </Link>
            </ul> */}
            <ul style={{ marginTop: '15px' }}>
              <Link to="/find-work" style={{ paddingTop: '3px' }}>
                Find Work
              </Link>
            </ul>
            <ul style={{ marginTop: '15px' }}>
              <Link to="/about" style={{ paddingTop: '3px' }}>
                About Us
              </Link>
            </ul>
          </li>
        </section>
        <section>
          <Link to="/formpage-a" className="navbar-header-button">
            Post A Project
          </Link>
        </section>
      </nav>

      <nav className="navbar-header-mobile">
        <section
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h1 onClick={() => setOpen(!open)} className="ham">
            <GiHamburgerMenu />
          </h1>
          <section
            className="youth"
            style={{ width: '100%', textAlign: 'center' }}
          >
            <Link to="./" style={{ marginLeft: '-15px' }}>
              <span className={'logo'} style={{ fontSize: '37px' }}>
                GigPe™
              </span>
            </Link>
          </section>
        </section>

        <div
          className="nav-links"
          style={{
            display: 'flex',
            transform: open ? 'translateX(0px)' : '',
            width: '100%',
          }}
        >
          <ul style={{ width: '60%' }}>
            <Div100vh>
              {/* <Link to="/" onClick={() => setOpen(false)}>
                <li>Business</li>
              </Link> */}
              <Link to="/find-work" onClick={() => setOpen(false)}>
                <li>Find Work</li>
              </Link>
              <Link to="/about" onClick={() => setOpen(false)}>
                <li>About Us</li>
              </Link>
              <Link to="/contact-us" onClick={() => setOpen(false)}>
                <li>Contact Us</li>
              </Link>
              <Link to="/formpage-a" onClick={() => setOpen(false)}>
                <li>Post a Project</li>
              </Link>
            </Div100vh>
          </ul>

          <Div100vh
            style={{
              width: '40%',
              background: 'rgba(0, 0, 0, 0.5)',
              opacity: '0.3',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(false)}
          ></Div100vh>
        </div>
      </nav>
    </div>
  );

  // <ul className="nav-links"
  //     style={{ transform: open ? "translateX(0px)" : "" }}
  // >
  //     <Div100vh>
  //         <Link to="/" onClick={() => setOpen(false)}> <li>Find Workers</li></Link>
  //         <Link to="/find-work" onClick={() => setOpen(false)}> <li >Find Work</li></Link>
  //         <Link to="/about" onClick={() => setOpen(false)}> <li >About Us</li></Link>
  //         <Link to="/formpage-a" onClick={() => setOpen(false)} > <li >Post A Project</li></Link>
  //         <Link to="/about" onClick={() => setOpen(false)}><li>About Us</li></Link>
  //     </Div100vh>
  // </ul>

  // <section style={{
  //     display: 'flex',
  //     flexDirection: 'row',
  //     alignItems: 'center'
  // }}>
  //     <h1 onClick={() => setOpen(!open)} className="ham"><GiHamburgerMenu /></h1>
  //     <section className="youth" style={{ width: '100%', textAlign: 'center' }}>
  //         <Link to="./">
  //             <img alt="logo" src={logo} style={{ width: '35%' }}></img>
  //         </Link>
  //     </section>
  // </section>

  //         </nav>

  //     </div>
  // )
}
