import React from 'react'
import Navbar from './components/Navbar'
import './App.css'
import Homepage from './pages/Homepage'
import FormpageA from './pages/FormpageA'
import FormpageB from './pages/FormpageB'
import Findwork from './pages/Findwork'
import VerificationPage from "./pages/VerificationPage"
import Covid from './pages/Covid'
import Aboutus from './pages/Aboutus'
import FindworkA from './pages/FindworkA'
import { Route, Switch } from 'react-router-dom'
import Last from './components/Last'
import Patch from './components/Patch'
import Handle404 from "./pages/Handle404"
import Footer from "./components/Footer"
import ContactUs from './pages/ContactUs'
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TandC from "./pages/TandC"
import Faqs from './pages/Faq'

export default function App() {
    return (
        <div>
            <Navbar />
            <Switch >
                <Route exact path="/formpage-a" component={FormpageA} />
                <Route exact path="/" component={Homepage} />
                <Route exact path="/find-work" component={Findwork} />
                <Route exact path="/covid-19-support" component={Covid} />
                <Route exact path="/about" component={Aboutus} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/faq" component={Faqs} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
                <Route exact path="/t&c" component={TandC}/>
                <Route path="/admin/email-verification" component={VerificationPage}/>
                <Route component={Handle404}/>
            </Switch>
            <Patch />
            <Last />
            <Footer/>
        </div>
    )
}
