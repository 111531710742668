import React from 'react'
import About from '../components/About'
import Who from '../components/Who'
import AboutUsBanner from "../components/AboutUsBanner"
import Happy from '../components/Happy'

export default function Aboutus() {
    React.useEffect(() => { window.scrollTo(0, 0); }, []);
    return (
        <div style={{maxWidth:'100%'}}>
            
            <About/>
            <Who/>
            <AboutUsBanner/>
            <Happy/>
           
        </div>
    )
}
