import React from 'react';
import kudi from '../images/kudi.png';
import beardo from '../images/beardo.png';
import group from '../images/group.png';
import masking from '../images/masking.png';
import glove from '../images/glove.png';
import cashless from '../images/cashless.png';
import temp from '../images/temp.png';
import wash from '../images/wash.png';
import dist from '../images/dist.png';

export default function Features() {
  return (
    <div>
      <div className="features">
        <div className="feat1">
          <p>
            We at GigPe™ have always supported those in need. Even during this
            crisis, we continued to thrive and did our bit by keeping the bread
            and butter for many intact.
            <br></br>
            Our Work cycle takes care of all the safety prorocols provided by
            WHO and thus we are ready to help you grow with our campaign -{' '}
            <span style={{ color: 'orangered' }}> GigPe™ Cares.</span>
            <br></br>
            <br></br>
            GigPe™ Cares is an initiative by us , under which we take
            responsible measures in order to keep our clients and gig workers
            safe from the threat of Covid-19.
          </p>
          <img src={kudi}></img>
        </div>

        <div className="yfg">
          <h2 style={{ marginTop: '0px' }}> GigPe™ for gig workers</h2>
          <hr></hr>
        </div>
        <div className="feat2">
          <img src={beardo}></img>

          <p>
            {
              <ul className="covidlistitemspar">
                <li className="covidlistitem">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  Thermal Screening
                </li>
                <li className="covidlistitem">
                  <i class="fa fa-arrow-right"></i>Masks & Sanitizers
                </li>
                <li className="covidlistitem" style={{ listStyle: 'none' }}>
                  <i class="fa fa-arrow-right"></i>Safety Kit
                </li>
                <li className="covidlistitem">
                  <i class="fa fa-arrow-right"></i>Digital Payments
                </li>
                <li className="covidlistitem">
                  <i class="fa fa-arrow-right"></i>Remote Work
                </li>
                <li className="covidlistitem">
                  <i class="fa fa-arrow-right"></i>Online Skills
                </li>
              </ul>
            }
          </p>
        </div>
        <div className="yfb">
          <h2> GigPe™ for Businesses</h2>
          <hr></hr>
        </div>
        <div className="feat3">
          <p>
            {
              <ul>
                <li className="yfblines">
                  <i class="fa fa-arrow-right"></i>Remote & Temporary Workers
                </li>
                <li className="yfblines">
                  <div>
                    <i class="fa fa-arrow-right"></i>
                    <p
                      className="yfblines"
                      style={{
                        width: '85%',
                        marginTop: '-30px',
                        marginLeft: '20px',
                      }}
                    >
                      Transformed fixed costs into variable costs{' '}
                    </p>
                  </div>
                </li>
                <li className="yfblines">
                  <i class="fa fa-arrow-right"></i>Digital Payments
                </li>
                <li className="yfblines">
                  <i class="fa fa-arrow-right"></i>Audio/Video Interviews
                </li>
              </ul>
            }
          </p>
          <img src={group}></img>
        </div>
      </div>

      <div className="precautions">
        <img className="pi1" src={masking}></img>
        <img className="pi2" src={temp}></img>
        <img className="pi1" src={wash}></img>
        <img className="pi2" src={dist}></img>
        <img className="pi1" src={glove}></img>
        <img className="pi2" src={cashless}></img>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <div className="times">
        <p>
          <b>
            The times may be tough, but we all are in this together. Don't you
            worry, GigPe™ has got your back!
          </b>
        </p>
      </div>
    </div>
  );
}
