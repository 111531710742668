import React, { useState } from 'react';
// import Heroi from './Heroi'
// import { AiOutlinePieChart } from 'react-icons/ai'
// import { AiFillTags } from 'react-icons/ai'
// import { BsGraphUp} from 'react-icons/bs'
// import { FaResearchgate } from 'react-icons/fa'
// import { MdPeopleOutline } from 'react-icons/md'
// import { HiSpeakerphone } from 'react-icons/hi'
import Card from 'react-bootstrap/Card';
import onee from '../images/onee.png';
import twoo from '../images/twoo.png';
import threee from '../images/threee.png';
import fourr from '../images/fourr.png';
import fivee from '../images/fivee.png';
import sixx from '../images/sixx.png';
import a from '../images/a.png';
import b from '../images/b.png';
import c from '../images/c.png';
import d from '../images/d.png';
import e from '../images/e.png';
import f from '../images/f.png';
import ra from '../images/ra.png';
import marketing from '../images/marketing.png';
import product from '../images/product.png';
import sales from '../images/sales.png';
import Operations from '../images/Operations.png';
import Research from '../images/Research.png';
import Recruiting from '../images/Recruiting.png';
import sales1 from '../images/sales1.png';
import product1 from '../images/product1.png';
import marketing1 from '../images/marketing1.png';
import creativepool1 from '../images/creativepool1.png';
import research1 from '../images/research1.png';
import operations1 from '../images/operations1.png';

export default function Buss() {
  const [c1s1, setC1] = useState(true);
  const [c2s1, setC2] = useState(true);
  const [c3s1, setC3] = useState(true);
  const [c4s1, setC4] = useState(true);
  const [c5s1, setC5] = useState(true);
  const [c6s1, setC6] = useState(true);

  return (
    <div className="bus-div">
      <>
        <hr class="new4"></hr>
        <h2 className="bus">SCALE YOUR BUSINESS WITH GigPe™</h2>

        <div className="card-holder">
          <div class="container">
            <div class="row buss-row">
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card1">
                  <section className="crdsec1 crd1sec1">
                    <img src={marketing} />
                    <p className="crdsec-heading">Marketing</p>
                    <p>
                      Leverage the network of our feet-on-street agents,
                      micro/macro influencers, content creators to drive offline
                      or online marketing initiatives.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd1sec2"
                    style={{ backgroundColor: '#EFC84B', color: 'white' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={marketing1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Marketing
                      </p>
                    </div>
                    <ul>
                      <li>Merchant Onboarding</li>
                      <li>Feet On Street Activations</li>
                      <li>Influencer Marketing</li>
                      <li>Campus Marketing</li>
                      <li>Sample Marketing</li>
                      <li>WhatsApp/Instagram/Facebook Marketing</li>
                    </ul>
                  </section>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card2">
                  <section className="crdsec1 crd2sec1">
                    <img src={product} />
                    <p className="crdsec-heading">Product</p>
                    <p>
                      With the help of our on-demand trained workforce generate
                      and analyse data or perform market research activities.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd2sec2"
                    style={{ backgroundColor: '#0FB591', color: 'white' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={product1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Product
                      </p>
                    </div>
                    <ul>
                      <li>Market Research</li>
                      <li>Data Collection</li>
                      <li>Digitisation</li>
                      <li>Geo Tagging</li>
                      <li>Data Entry</li>
                    </ul>
                  </section>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card3">
                  <section className="crdsec1 crd3sec1">
                    <img src={sales} />
                    <p className="crdsec-heading">Sales</p>
                    <p>
                      Sales Targets? Don't worry! Our on-demand tele-sales
                      executives, business developers and sales associates can
                      make it easier for you.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd3sec2"
                    style={{
                      backgroundColor: '#6543B1',
                      color: 'white',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={sales1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Sales
                      </p>
                    </div>
                    <ul>
                      <li>Tele-Sales</li>
                      <li>Customer Support</li>
                      <li>Field Sales</li>
                      <li>Lead Generation</li>
                      <li>Retail Sales</li>
                      <li>FMCG Sales</li>
                    </ul>
                  </section>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card4">
                  <section className="crdsec1 crd4sec1">
                    <img src={Operations} />
                    <p className="crdsec-heading">Operations</p>
                    <p>
                      Don't get stuck! Optimise your business operations with
                      the help of on-demand auditors, moderators and operation
                      executives.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd4sec2"
                    style={{
                      backgroundColor: '#FFA55F',
                      color: 'white',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={operations1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Operations
                      </p>
                    </div>
                    <ul>
                      <li>Retail Audits</li>
                      <li>Field Ops</li>
                      <li>Account Management</li>
                      <li>Event Management</li>
                      <li>Mystery Shopping</li>
                      <li>On-ground Execution Support</li>
                    </ul>
                  </section>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card5">
                  <section className="crdsec1 crd5sec1">
                    <img src={Research} />
                    <p className="crdsec-heading">Research</p>
                    <p>
                      Thinking about launching something new? Let our workforce
                      help you find the right target audience across industries.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd5sec2"
                    style={{
                      backgroundColor: '#27CDDF',
                      color: 'white',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={research1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Research
                      </p>
                    </div>
                    <ul>
                      <li>Mystery Audit</li>
                      <li>In-depth Interviews</li>
                      <li>Field Research</li>
                      <li>Online Survey</li>
                    </ul>
                  </section>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12">
                <div className="buss-cards card6">
                  <section className="crdsec1 crd6sec1">
                    <img src={Recruiting} />
                    <p className="crdsec-heading">Creative Pool</p>
                    <p>
                      Say hello to creativity! Find out talented artists,
                      designers, content or copy writers and much more.
                    </p>
                  </section>

                  <section
                    className="onHover crdsec2 crd6sec2"
                    style={{ backgroundColor: '#4E6ED9', color: 'white' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={creativepool1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Creative Pool
                      </p>
                    </div>
                    <ul>
                      <li>Artists (Make-up/Face, Anchor/EMCEE etc.)</li>
                      <li>Photo/Videographers</li>
                      <li>Content or Copy Writers</li>
                      <li>Designers</li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="container-tablets-mobile">
            <div class="row buss-row">
              <div class="col-md-6 col-xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC1(!c1s1)}
                    style={{ display: c1s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={marketing} />
                    <p className="crdsec-heading">Marketing</p>
                    <p>
                      Leverage the network of our feet-on-street agents,
                      micro/macro influencers, content creators to drive offline
                      or online marketing initiatives.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>

                  <section
                    onClick={() => setC1(!c1s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#EFC84B',
                      color: 'white',
                      display: !c1s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={marketing1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Marketing
                      </p>
                    </div>
                    <ul>
                      <li>Merchant Onboarding</li>
                      <li>Feet On Street Activations</li>
                      <li>Influencer Marketing</li>
                      <li>Campus Marketing</li>
                      <li>Sample Marketing</li>
                      <li>WhatsApp/Instagram/Facebook Marketing</li>
                    </ul>
                    <button>
                      <i class=" left-arrow fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC2(!c2s1)}
                    style={{ display: c2s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={product} />
                    <p className="crdsec-heading">Product</p>
                    <p>
                      With the help of our on-demand trained workforce generate
                      and analyse data or perform market research activities.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>

                  <section
                    onClick={() => setC2(!c2s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#0FB591',
                      color: 'white',
                      display: !c2s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={product1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Product
                      </p>
                    </div>
                    <ul>
                      <li>Market Research</li>
                      <li>Data Collection</li>
                      <li>Digitisation</li>
                      <li>Geo Tagging</li>
                      <li>Data Entry</li>
                    </ul>
                    <button>
                      <i class="left-arrow  fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC3(!c3s1)}
                    style={{ display: c3s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={sales} />
                    <p className="crdsec-heading">Sales</p>
                    <p>
                      Sales Targets? Don't worry! Our on-demand tele-sales
                      executives, business developers and sales associates can
                      make it easier for you.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>
                  <section
                    onClick={() => setC3(!c3s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#6543B1',
                      color: 'white',
                      display: !c3s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={sales1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Sales
                      </p>
                    </div>
                    <ul>
                      <li>Tele-Sales</li>
                      <li>Customer Support</li>
                      <li>Field Sales</li>
                      <li>Lead Generation</li>
                      <li>Retail Sales</li>
                      <li>FMCG Sales</li>
                    </ul>
                    <button>
                      <i class="left-arrow  fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC4(!c4s1)}
                    style={{ display: c4s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={Operations} />
                    <p className="crdsec-heading">Operations</p>
                    <p>
                      Don't get stuck! Optimise your business operations with
                      the help of on-demand auditors, moderators and operation
                      executives.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>

                  <section
                    onClick={() => setC4(!c4s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#FFA55F',
                      color: 'white',
                      display: !c4s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={operations1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Operations
                      </p>
                    </div>
                    <ul>
                      <li>Retail Audits</li>
                      <li>Field Ops</li>
                      <li>Account Management</li>
                      <li>Event Management</li>
                      <li>Mystery Shopping</li>
                      <li>On-ground Execution Support</li>
                    </ul>
                    <button>
                      <i class="left-arrow  fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC5(!c5s1)}
                    style={{ display: c5s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={Research} />
                    <p className="crdsec-heading">Research</p>
                    <p>
                      Thinking about launching something new? Let our workforce
                      help you find the right target audience across industries.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>

                  <section
                    onClick={() => setC5(!c5s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#27CDDF',
                      color: 'white',
                      display: !c5s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={research1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Research
                      </p>
                    </div>
                    <ul>
                      <li>Mystery Audit</li>
                      <li>In-depth Interviews</li>
                      <li>Field Research</li>
                      <li>Online Survey</li>
                    </ul>
                    <button>
                      <i class="left-arrow  fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
              <div class="col-md-6 col xs-12">
                <div className="buss-cards">
                  <section
                    onClick={() => setC6(!c6s1)}
                    style={{ display: c6s1 ? 'block' : 'none' }}
                    className="mobcrdsec1"
                  >
                    <img src={Recruiting} />
                    <p className="crdsec-heading">Creative Pool</p>
                    <p>
                      Say hello to creativity! Find out talented artists,
                      designers, content or copy writers and much more.
                    </p>
                    <button>
                      <i class="right-arrow fa fa-arrow-right"></i>
                    </button>
                  </section>

                  <section
                    onClick={() => setC6(!c6s1)}
                    className="mobcrdsec2"
                    style={{
                      backgroundColor: '#4E6ED9',
                      color: 'white',
                      display: !c6s1 ? 'block' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img src={creativepool1} />
                      <p
                        className="crdsec-heading"
                        style={{ marginLeft: '10px' }}
                      >
                        Creative Pool
                      </p>
                    </div>
                    <ul>
                      <li>Artists (Make-up/Face, Anchor/EMCEE etc.)</li>
                      <li>Photo/Videographers</li>
                      <li>Content or Copy Writers</li>
                      <li>Designers</li>
                    </ul>
                    <button>
                      <i class="left-arrow  fa fa-arrow-left"></i>
                    </button>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
