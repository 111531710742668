import React from 'react'
import aboutUsheader from "../images/aboutUsheader.png"
import aboutUsheaderMobile from "../images/aboutUsMobile2.png"
export default function About() {

    return (
        <div className="about-main-div">
        <div className="about">
            <div>
                <hr style={{margin:'0 auto'
                        }} className="new3"></hr>
                <h1>Nurturing the power of the youth !</h1>
            </div>
            <div className="about-div">
                <img src={aboutUsheader} className="lap-tab-img"></img>
            </div>
            <div className="about-div-mob">
                <img src={aboutUsheaderMobile} className="mob-img"></img>
            </div>
        </div>
        </div>
    )

}
