import React from 'react';
import brands from '../images/landing_brands.png';

export default function Banner() {
  return (
    <div className="branding1">
      <h2 className="bannerh2">
        From The Team Who Helped 50+ Businesses Scale
      </h2>
      <img className="br1" src={brands}></img>
    </div>
  );
}
