import Modal from 'react-bootstrap/Modal'
import { withRouter,Link } from "react-router-dom";
import {Redirect} from "react-router"
import modalDone from "../images/modalDone.png"
import Button from 'react-bootstrap/Button'


function MyVerticallyCenteredModal2(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <section className="modal-first-form-complete" style={{textAlign:'center'}}>
                        <img src={modalDone} className="modal-success"></img>
                        <h2>Awesome!</h2>
                        <p>Your email address is succesfully verified</p>
                        <Button style={{ backgroundColor:'#6543B1', color:'white'}} onClick={()=>{
                            props.setIsSubmitted(false);
                            props.setPartOne(true);
                        }}>Continue</Button>
                    </section>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        );

}


export default withRouter(MyVerticallyCenteredModal2);