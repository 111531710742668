import React from 'react'
import Card from 'react-bootstrap/Card'
import marketing from "../images/marketing.png"
import product from "../images/product.png"
import sales from "../images/sales.png"
import Operations from "../images/Operations.png"
import Research from "../images/Research.png"
import Recruiting from "../images/Recruiting.png"
import sales1 from "../images/sales1.png"
import product1 from "../images/product1.png"
import marketing1 from "../images/marketing1.png"
import creativepool1 from "../images/creativepool1.png"
import research1 from "../images/research1.png"
import operations1 from "../images/operations1.png"

export default function NewBuss() {
    return (
        <div className="fwbuss" style={{ overflow: 'hidden' }}>
            <div className="newbussa">
                <hr className="fwhr"></hr>
                <h2 className="fwh2">TRY MULTIPLE GIGS <br></br> AND INDUSTRIES</h2>
            </div>

            {/* <div className="newbuss">

                <Card className="newbuss-cards">
                    <img className="nbcim" src={marketing}></img>
                    <h2 className="nbch2">Marketing</h2>
                </Card>

                <Card className="newbuss-cards">
                    <img className="nbcim" src={product}></img>
                    <h2 className="nbch2">Product</h2>
                </Card>

                <Card className="newbuss-cards">
                    <img className="nbcim" src={sales}></img>
                    <h2 className="nbch2">Sales</h2>
                </Card>

                <Card className="newbuss-cards">
                    <img className="nbcim" src={Operations}></img>
                    <h2 className="nbch2">Operations</h2>
                </Card>

                <Card className="newbuss-cards">
                    <img className="nbcim" src={Research}></img>
                    <h2 className="nbch2">Research</h2>
                </Card>

                <Card className="newbuss-cards">
                    <img className="nbcim" src={Recruiting}></img>
                    <h2 className="nbch2">Recruiting</h2>
                </Card>

            </div>
         */}


            <div className="card-holder">

                <div class="container">
                    <div class="row buss-row">
                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards">
                                <section className="crdsec2" style={{ backgroundColor: '#EFC84B', color: 'white' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img style={{
                                            width: '40px',
                                            height:'40px'}} src={marketing1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Marketing</p>
                                    </div>
                                    <ul>
                                        <li>Merchant Onboarding</li>
                                        <li>Feet On Street Activations</li>
                                        <li>Influencer Marketing</li>
                                        <li>Campus Marketing</li>
                                        <li>Sample Marketing</li>
                                        <li>WhatsApp/Instagram/Facebook Marketing</li>
                                    </ul>
                                </section>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards">
                                <section className="crdsec2" style={{ backgroundColor: '#0FB591', color: 'white' }} >
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        src={product1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Product</p>
                                    </div>
                                    <ul>
                                        <li>Market Research</li>
                                        <li>Data Collection</li>
                                        <li>Digitisation</li>
                                        <li>Geo Tagging</li>
                                        <li>Data Entry</li>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards">
                                <section className="crdsec2" style={{ backgroundColor: '#6543B1', color: 'white' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img
                                        style={{
                                            width: '40px',
                                            height:'40px'}}
                                        src={sales1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Sales</p>
                                    </div>
                                    <ul>
                                        <li>Tele-Sales</li>
                                        <li>Customer Support</li>
                                        <li>Field Sales</li>
                                        <li>Lead Generation</li>
                                        <li>Retail Sales</li>
                                        <li>FMCG Sales</li>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards">

                                <section className="crdsec2" style={{ backgroundColor: '#FFA55F', color: 'white' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                        style={{
                                            width: '40px',
                                            height:'40px'}}
                                        src={operations1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Operations</p>
                                    </div>
                                    <ul>
                                        <li>Retail Audits</li>
                                        <li>Field Ops</li>
                                        <li>Account Management</li>
                                        <li>Event Management</li>
                                        <li>Mystery Shopping</li>
                                        <li>On-ground Execution Support</li>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards ">
                                <section className="crdsec2" style={{
                                    backgroundColor: '#27CDDF', color: 'white'
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <img
                                        style={{
                                            width: '40px',
                                            height:'40px'}}
                                        src={research1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Research</p>
                                    </div>
                                    <ul>
                                        <li>Mystery Audit</li>
                                        <li>In-depth Interviews</li>
                                        <li>Field Research</li>
                                        <li>Online Survey</li>
                                    </ul>
                                </section>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-xs-12">
                            <div className="buss-cards">
                                <section className="crdsec2" style={{ backgroundColor: '#4E6ED9', color: 'white' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        src={creativepool1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Creative Pool</p>
                                    </div>
                                    <ul>
                                        <li>Artists (Make-up/Face, Anchor/EMCEE etc.)</li>
                                        <li>Photo/Videographers</li>
                                        <li>Content or Copy Writers</li>
                                        <li>Designers</li>
                                    </ul>
                                </section>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="container-tablets-mobile">
                    <div class="row buss-row">
                        <div class="col-md-6 col-xs-12">
                            <div className="buss-cards">
                
                                <section className="mobcrdsec2" style={{ backgroundColor: '#EFC84B', color: 'white'}}>
                                    <div style={{ display: 'flex' }}>
                                        <img
                                        style={{
                                            width: '40px',
                                            height:'40px'}}
                                        src={marketing1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Marketing</p>
                                    </div>
                                    <ul>
                                        <li>Merchant Onboarding</li>
                                        <li>Feet On Street Activations</li>
                                        <li>Influencer Marketing</li>
                                        <li>Campus Marketing</li>
                                        <li>Sample Marketing</li>
                                        <li>WhatsApp/Instagram/Facebook marketing</li>
                                    </ul>
                                </section>
                   
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12">
                                <div className="buss-cards">

                                    <section className="mobcrdsec2" style={{ backgroundColor: '#0FB591', color: 'white'}} >
                                        <div style={{ display: 'flex' }}>
                                            <img 
                                            style={{
                                            width: '40px',
                                            height:'40px'}}
                                            
                                            src={product1} />
                                            <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Product</p>
                                        </div>
                                        <ul>
                                            <li>Market Research</li>
                                            <li>Data Collection</li>
                                            <li>Digitisation</li>
                                            <li>Geo Tagging</li>
                                            <li>Data Entry</li>
                                        </ul>
                                    </section>

                                </div>
                            </div>

                        <div class="col-md-6 col-xs-12">
                            <div className="buss-cards">
                                <section className="mobcrdsec2" style={{backgroundColor: '#6543B1', color: 'white'    }} >
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                        
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        src={sales1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Sales</p>
                                    </div>
                                    <ul>
                                        <li>Tele-Sales</li>
                                        <li>Customer Support</li>
                                        <li>Field Sales</li>
                                        <li>Lead Generation</li>
                                        <li>Retail Sales</li>
                                        <li>FMCG Sales</li>
                                    </ul>

                                </section>
</div>
</div>

                        <div class="col-md-6 col-xs-12">
                            <div className="buss-cards">

                                <section className="mobcrdsec2" style={{backgroundColor: '#FFA55F', color: 'white'}}>
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        
                                        src={operations1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Operations</p>
                                    </div>
                                    <ul>
                                        <li>Retail Audits</li>
                                        <li>Field Ops</li>
                                        <li>Account Management</li>
                                        <li>Event Management</li>
                                        <li>Mystery Shopping</li>
                                        <li>On-ground Execution Support</li>
                                    </ul>
                                </section>
</div>
</div>

                        <div class="col-md-6 col-xs-12">
                            <div className="buss-cards">


                                <section className="mobcrdsec2" style={{
                                    backgroundColor: '#27CDDF', color: 'white'}}>
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        src={research1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Research</p>
                                    </div>
                                    <ul>
                                        <li>Mystery Audit</li>
                                        <li>In-depth Interviews</li>
                                        <li>Field Research</li>
                                        <li>Online Survey</li>
                                    </ul>
                                </section>

</div>
</div>


<div class="col-md-6 col xs-12">
                                <div className="buss-cards">
                                <section className="mobcrdsec2" style={{ backgroundColor: '#4E6ED9', color: 'white' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img 
                                            style={{
                                                width: '40px',
                                                height: '40px'
                                            }}
                                        
                                        src={creativepool1} />
                                        <p className="crdsec-heading" style={{ marginLeft: '10px' }}>Creative Pool</p>
                                    </div>
                                    <ul>
                                        <li>Artists (Make-up/Face, Anchor/EMCEE etc.)</li>
                                        <li>Photo/Videographers</li>
                                        <li>Content or Copy Writers</li>
                                        <li>Designers</li>
                                    </ul>
                                </section>

                            </div>
                            </div>

                            



                    </div>
                </div>


            </div>






        </div>


        //     <div className="card-holder">

        //         <div class="container">
        //             <div class="row buss-row">

        //                 <div class="col-lg-4 col-md-6 col-xs-12">

        //                     <div className="buss-cards card1">
        //                         <section className="crdsec1 crd1sec1">
        //                             <img src={marketing} />
        //                             <p className="crdsec-heading">Marketing</p>
        //                             <p>Leverage the network of our feet-on-street agents, micro/macro influencers, content creators to drive offline or online marketing initiatives.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd1sec2" style={{ backgroundColor: '#F3CB3A', color: 'white' }}>
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={marketing} />
        //                                 <p className="crdsec-heading">Marketing</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Merchant Onboarding</li>
        //                                 <li>Feet On Street Activations</li>
        //                                 <li>Influencer Marketing</li>
        //                                 <li>Campus Marketing</li>
        //                                 <li>Sample Marketing</li>
        //                                 <li>WhatsApp/Instagram/Facebook marketing</li>
        //                             </ul>
        //                         </section>
        //                     </div>
        //                 </div>

        //                 <div class="col-lg-4 col-md-6 col-xs-12">
        //                     <div className="buss-cards card2">
        //                         <section className="crdsec1 crd2sec1">
        //                             <img src={product} />
        //                             <p className="crdsec-heading">Product</p>
        //                             <p>With the help of our on-demand trained workforce generate and analyse data or perform market research activities.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd2sec2" style={{ backgroundColor: '#00BD94', color: 'white' }} >
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={product} />
        //                                 <p className="crdsec-heading">Product</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Market Research</li>
        //                                 <li>Data Collection</li>
        //                                 <li>Digitisation</li>
        //                                 <li>Geo Tagging</li>
        //                                 <li>Data Entry</li>
        //                             </ul>
        //                         </section>

        //                     </div>
        //                 </div>

        //                 <div class="col-lg-4 col-md-6 col-xs-12">
        //                     <div className="buss-cards card3">

        //                         <section className="crdsec1 crd3sec1">
        //                             <img src={sales} />
        //                             <p className="crdsec-heading">Sales</p>
        //                             <p>Sales Targets? Don't worry! Our on-demand tele-sales executives, business developers and sales associates can make it easier for you.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd3sec2" style={{
        //                             backgroundColor: '#7327B3', color: 'white'
        //                         }} >
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={sales} />
        //                                 <p className="crdsec-heading">Sales</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Tele-Sales</li>
        //                                 <li>Customer Support</li>
        //                                 <li>Field Sales</li>
        //                                 <li>Lead Generation</li>
        //                                 <li>Retail Sales</li>
        //                                 <li>FMCG Sales</li>
        //                             </ul>
        //                         </section>
        //                     </div>

        //                 </div>



        //                 <div class="col-lg-4 col-md-6 col-xs-12">
        //                     <div className="buss-cards card4">
        //                         <section className="crdsec1 crd4sec1">
        //                             <img src={Operations} />
        //                             <p className="crdsec-heading">Operations</p>
        //                             <p>Don't get stuck! Optimise your business operations with the help of on-demand auditors, moderators and operation executives.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd4sec2" style={{
        //                             backgroundColor: '#FF7A4F', color: 'white'
        //                         }}>
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={Operations} />
        //                                 <p className="crdsec-heading">Operations</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Retail Audits</li>
        //                                 <li>Field Ops</li>
        //                                 <li>Account Management</li>
        //                                 <li>Event Management</li>
        //                                 <li>Mystery Shopping</li>
        //                                 <li>On-ground Execution Support</li>
        //                             </ul>
        //                         </section>

        //                     </div>
        //                 </div>
        //                 <div class="col-lg-4 col-md-6 col-xs-12">
        //                     <div className="buss-cards card5">
        //                         <section className="crdsec1 crd5sec1">
        //                             <img src={Research} />
        //                             <p className="crdsec-heading">Research</p>
        //                             <p>Thinking about launching something new? Let our workforce help you find the right target audience across industries.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd5sec2" style={{
        //                             backgroundColor: '#00D1E3', color: 'white'
        //                         }}>
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={Research} />
        //                                 <p className="crdsec-heading">Research</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Mystery Audit</li>
        //                                 <li>In depth Interviews</li>
        //                                 <li>Field Research</li>
        //                                 <li>Online Survey</li>
        //                             </ul>
        //                         </section>

        //                     </div>
        //                 </div>
        //                 <div class="col-lg-4 col-md-6 col-xs-12">
        //                     <div className="buss-cards card6">
        //                         <section className="crdsec1 crd6sec1">
        //                             <img src={Recruiting} />
        //                             <p className="crdsec-heading">Creative Pool</p>
        //                             <p>Say hello to creativity! Find out talented artists, designers, content or copy writers and much more.</p>
        //                         </section>

        //                         <section className="onHover crdsec2 crd6sec2" style={{ backgroundColor: '#5560DC', color: 'white' }}>
        //                             <div style={{ display: 'flex' }}>
        //                                 <img src={Recruiting} />
        //                                 <p className="crdsec-heading">Creative Pool</p>
        //                             </div>
        //                             <ul>
        //                                 <li>Artists (Make-up/Face, Anchor/EMCEE etc.)</li>
        //                                 <li>Photo/Videographers</li>
        //                                 <li>Content or Copy Writers</li>
        //                                 <li>Designers</li>
        //                             </ul>
        //                         </section>


        //                     </div>
        //                 </div>
        //             </div>
        //         </div>

        //     </div>


        // </div> 

    )
}
