import React from 'react';
import page404 from '../images/pagenotfound.gif';

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <div className="privacy-policy" style={{marginTop:'100px'}}> */}

      {/*                 
<p className="privacy-policy-heading">Privacy Policy</p>


<div>


<p>http://www. GigPe™.com is the registered domain name and official website of Vibecheck Technologies Pvt. Ltd. . This privacy policy sets out how Vibecheck Technologies Pvt. Ltd. , uses and protects any information that you give to Vibecheck Technologies Pvt. Ltd.  when you use http://www. GigPe™.com. Vibecheck Technologies Pvt. Ltd.  recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on http://www. GigPe™.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers and freelancers. By visiting and/or using our website, you agree to this Privacy Policy. Vibecheck Technologies Pvt. Ltd.  is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. Vibecheck Technologies Pvt. Ltd.  may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01st January, 2018.
</p>

<p>
Please read this Privacy Policy along with the Terms of Use linked separately to our website, http://www. GigPe™.com By using this website, you signify your assent to this Privacy Policy. Please do not use this website, if you do not agree with any of the stipulated terms and/or conditions which require your adherence prior to use. We reserve the right, at our discretion, to change, modify, add or remove portions of this Policy at any time. Please check this page periodically for changes. Your continued use of our website following the changes to the terms and/or conditions shall mean you accept those changes in totality. For any clarifications in this matter or in general regarding this Privacy Policy you may email Vibecheck Technologies Pvt. Ltd.  at info@ GigPe™.com.
</p>

<p>
Our website may contain links to other websites. We are not in anyway responsible for the privacy practices or content of such websites and hereby expressly disclaim all liability associated with the content and use thereof. Vibecheck Technologies Pvt. Ltd.  does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide services requested and permitted by you to Vibecheck Technologies Pvt. Ltd.  to process the same, or under the following circumstances:
</p>

<ul>
<li>We provide the information to trusted partners who work on behalf of or with Vibecheck Technologies Pvt. Ltd.  under confidentiality agreements. These companies may use your personal information to help Vibecheck Technologies Pvt. Ltd.  communicate with you about offers from Vibecheck Technologies Pvt. Ltd.  and our marketing partners. However, these companies do not have any independent right to share this information. In case these companies do share the information, Vibecheck Technologies Pvt. Ltd.  would not be held liable for the same; and</li>
                            <li>	We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims; and</li>

<li>	We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Vibecheck Technologies Pvt. Ltd. 's terms of use, or as otherwise required by law; and
    </li>

                            <li>	We transfer information about you if Vibecheck Technologies Pvt. Ltd.  is acquired by or merged with another company; and</li>

                            <li>Vibecheck Technologies Pvt. Ltd.  does not provide any personal information to any advertiser when you interact with or view a targeted ad. However, by interacting with or viewing an ad you are consenting to the possibility that the advertiser will make the assumption that you meet the targeting criteria used to display the ad.</li>

</ul>

                    <p>What we collect:</p>

                    <p>We collect the following information:</p>
<ul>
                    <li>name and job title; or</li>
                    <li>contact information including email address; or</li>
                    <li>qualification details of the freelancers; or</li>
                    <li>demographic information such as postcode, preferences and interests; or</li>
                    <li>other information relevant to freelancer/customer surveys and/or offers.</li>
</ul>


What we do with the information we gather:

                    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
<ul>
                        <li>Internal record keeping; or</li>
                        <li>We may use the information to improve our services; or</li>
                        <li>We may periodically send promotional emails about new special offers or other information, which we think you may find interesting using the email address which you have provided; or</li>
                        <li>	From time to time, we may also use your information to contact you for market research or job offer purposes. We may contact you by email, phone or mail. We may even use the information to customize the website according to your interests.</li>
</ul>
Cookies:

Cookies are small pieces of information stored in your computer. When you login on http://www. GigPe™.com, we store small cookies in your computer, to provide a better browsing experience for you. Any advertising company may use non-personal info about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies, mail us at info@ GigPe™.com.

Security:

We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.

Links to other websites:

Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over other website. Therefore, we cannot be responsible for the protection and privacy of any information, which you provide whilst visiting such sites and this privacy statement does not govern such sites. You should exercise caution and look at the privacy statement applicable to the website in question.

Controlling your personal information:

You may choose to restrict the collection or use of your personal information in the following ways:

	whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes; or
	if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@ GigPe™.com.

Taking care of your Password/s (Password security):

Vibecheck Technologies Pvt. Ltd.  firmly believes in protecting the personal information of the customer. Vibecheck Technologies Pvt. Ltd.  or any of its staff or official will never ask for any password or related information from its customers and the customers are also advised, in order to protect access to their accounts and to other channels, do not disclose any of our passwords to anyone. It is recommended that the customers necessarily choose their own passwords carefully such that any third party makes no unauthorized access. The customers are also advised not to keep any written or other record of the password/s so that no third party could access the same. The customers will also need to undertake not to disclose the password to anyone. In order that the passwords become complex and difficult for others to guess, you should carefully choose the alpha numeric passwords with combination of upper case alphabets and numbers and special characters (like @, %, &, ! etc.).

The password is private information and must be kept confidential by the owner of the username related to the password. If someone other than the owner knows the password, that username and password could be misused to access resources and information possibly with criminal intent, without notice since they would appear as the registered owner.

We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about our services, which we think you may find interesting if you tell us that you wish this to happen.

Vibecheck Technologies Pvt. Ltd.  undertakes not to disclose the information provided by the customer to any person unless such action is necessary to the extent required by law and for regulatory purposes.

If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect. Vibecheck Technologies Pvt. Ltd.  reserves all rights to the Website and its Contents. Some of the content & features mentioned in the terms of use, disclaimers and privacy policy may not be live currently but all the terms and conditions mentioned in this document would also be applicable to those content and features once those services go live.

We welcome your feedback concerning this Privacy Policy. Please send your comments, questions or desired clarifications to the Vibecheck Technologies Pvt. Ltd.  at:

Vibecheck Technologies Pvt. Ltd. 
Reg. Off.: J5/58, First Floor, J 5 & 6 Block, Rajouri Garden, Near Nehru Market, New Delhi - 110027
Tel.: +91 9999-722799
E-mail: info@ GigPe™.com

Jurisdiction:

If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, the laws of India shall govern any disputes arising under this Policy, and courts of Delhi only will have absolute jurisdiction over the subject matter involved.

******************** */}

      <div style={{ margin: '10%', marginTop: '115px' }} className="policy-div">
        <p
          style={{
            textAlign: 'center',
            background: 'transparent',
            marginBottom: '0cm',
            lineHeight: '100%',
          }}
        >
          <u>
            <strong className="privacy-policy-heading">PRIVACY POLICY</strong>
          </u>
        </p>

        <p
          style={{
            textAlign: 'center',
            background: 'transparent',
            marginBottom: '0cm',
            lineHeight: '100%',
          }}
        >
          <br />
        </p>

        <div className="privacy-text" style={{ marginTop: '50px' }}>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <u>http://www. GigPe™.com</u> is the registered domain name and
            official website of Vibecheck Technologies Pvt. Ltd. Online
            Solutions Private Limited. This privacy policy sets out how
            Vibecheck Technologies Pvt. Ltd. , uses and protects any information
            that you give to Vibecheck Technologies Pvt. Ltd. when you use{' '}
            <u>http://www. GigPe™.com</u>. vibecheck technologies Online
            Solutions Private Limited recognizes the importance of maintaining
            your privacy. We value your privacy and appreciate your trust in us.
            This Policy describes how we treat user information we collect on{' '}
            <u>http://www. GigPe™.com</u> and other offline sources. This
            Privacy Policy applies to current and former visitors to our website
            and to our online customers and freelancers. By visiting and/or
            using our website, you agree to this Privacy Policy. Vibecheck
            Technologies Pvt. Ltd. is committed to ensuring that your privacy is
            protected. Should we ask you to provide certain information by which
            you can be identified when using this website, then you can be
            assured that it will only be used in accordance with this privacy
            statement. vibecheck technologies may change this policy from time
            to time by updating this page. You should check this page from time
            to time to ensure that you are happy with any changes. This policy
            is effective from 01<sup>st</sup> January, 2018.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Please read this Privacy Policy along with the Terms of Use linked
            separately to our website, <u>http://www. GigPe™.com</u> By using
            this website, you signify your assent to this Privacy Policy. Please
            do not use this website, if you do not agree with any of the
            stipulated terms and/or conditions which require your adherence
            prior to use. We reserve the right, at our discretion, to change,
            modify, add or remove portions of this Policy at any time. Please
            check this page periodically for changes. Your continued use of our
            website following the changes to the terms and/or conditions shall
            mean you accept those changes in totality. For any clarifications in
            this matter or in general regarding this Privacy Policy you may
            email Vibecheck Technologies Pvt. Ltd. Online Solutions Private
            Limited at <u>info@ GigPe™.com</u>.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Our website may contain links to other websites. We are not in
            anyway responsible for the privacy practices or content of such
            websites and hereby expressly disclaim all liability associated with
            the content and use thereof. Vibecheck Technologies Pvt. Ltd. Online
            Solutions Private Limited does not rent, sell, or share personal
            information about you with other people or non-affiliated companies
            except to provide services requested and permitted by you to
            vibecheck technologies to process the same, or under the following
            circumstances:
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <ul>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We provide the information to trusted partners who work on
                behalf of or with Vibecheck Technologies Pvt. Ltd. Online
                Solutions Private Limited under confidentiality agreements.
                These companies may use your personal information to help
                vibecheck technologies communicate with you about offers from
                Vibecheck Technologies Pvt. Ltd. and our marketing partners.
                However, these companies do not have any independent right to
                share this information. In case these companies do share the
                information, Vibecheck Technologies Pvt. Ltd. Online Solutions
                Private Limited would not be held liable for the same; and
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We respond to subpoenas, court orders, or legal process, or to
                establish or exercise our legal rights or defend against legal
                claims; and
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We believe it is necessary to share information in order to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the physical safety of any person, violations of
                Vibecheck Technologies Pvt. Ltd. Online Solutions Private
                Limited's terms of use, or as otherwise required by law; and
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We transfer information about you if Vibecheck Technologies Pvt.
                Ltd. is acquired by or merged with another company; and
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                Vibecheck Technologies Pvt. Ltd. Online Solutions Private
                Limited does not provide any personal information to any
                advertiser when you interact with or view a targeted ad.
                However, by interacting with or viewing an ad you are consenting
                to the possibility that the advertiser will make the assumption
                that you meet the targeting criteria used to display the ad.
              </p>
            </li>
          </ul>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginLeft: '0.5cm',
              marginBottom: '0.28cm',
              lineHeight: '115%',
            }}
          >
            <br />
            &nbsp;
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>What we collect</strong>
            </u>
            :
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            We collect the following information:
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <ul>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '100%',
                }}
              >
                name and job title; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '100%',
                }}
              >
                contact information including email address; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '100%',
                }}
              >
                qualification details of the freelancers; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '100%',
                }}
              >
                demographic information such as postcode, preferences and
                interests; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '100%',
                }}
              >
                other information relevant to freelancer/customer surveys and/or
                offers.
              </p>
            </li>
          </ul>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>What we do with the information we gather:</strong>
            </u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <ul>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                Internal record keeping; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We may use the information to improve our services; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                We may periodically send promotional emails about new special
                offers or other information, which we think you may find
                interesting using the email address which you have provided; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                From time to time, we may also use your information to contact
                you for market research or job offer purposes. We may contact
                you by email, phone or mail. We may even use the information to
                customize the website according to your interests.
              </p>
            </li>
          </ul>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>Cookies:</strong>
            </u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Cookies are small pieces of information stored in your computer.
            When you login on <u>http://www. GigPe™.com</u>, we store small
            cookies in your computer, to provide a better browsing experience
            for you. Any advertising company may use non-personal info about
            your visits to this and other websites in order to provide
            advertisements about goods and services of interest to you. If you
            would like more information about this practice and to know your
            choices about not having this information used by these companies,
            mail us at <u>info@ GigPe™.com</u>
            <em>.</em>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>Security:</strong>
            </u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>Links to other websites:</strong>
            </u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information, which you provide whilst visiting such sites and
            this privacy statement does not govern such sites. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>Controlling your personal information:</strong>
            </u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <ul>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                whenever you are asked to fill in a form on the website, look
                for the box that you can click to indicate that you do not want
                the information to be used by anybody for direct marketing
                purposes; or
              </p>
            </li>
            <li>
              <p
                style={{
                  textAlign: 'justify',
                  background: 'transparent',
                  marginBottom: '0.28cm',
                  lineHeight: '115%',
                }}
              >
                if you have previously agreed to us using your personal
                information for direct marketing purposes, you may change your
                mind at any time by writing to or emailing us at{' '}
                <u>info@ GigPe™.com</u>.
              </p>
            </li>
          </ul>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <u>
              <strong>Taking care of your Password/s (Password security</strong>
            </u>
            <strong>):</strong>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Vibecheck Technologies Pvt. Ltd. firmly believes in protecting the
            personal information of the customer. Vibecheck Technologies Pvt.
            Ltd. Online Solutions Private Limited or any of its staff or
            official will never ask for any password or related information from
            its customers and the customers are also advised, in order to
            protect access to their accounts and to other channels, do not
            disclose any of our passwords to anyone. It is recommended that the
            customers necessarily choose their own passwords carefully such that
            any third party makes no unauthorized access. The customers are also
            advised not to keep any written or other record of the password/s so
            that no third party could access the same. The customers will also
            need to undertake not to disclose the password to anyone. In order
            that the passwords become complex and difficult for others to guess,
            you should carefully choose the alpha numeric passwords with
            combination of upper case alphabets and numbers and special
            characters (like @, %, &amp;, ! etc.).
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            The password is private information and must be kept confidential by
            the owner of the username related to the password. If someone other
            than the owner knows the password, that username and password could
            be misused to access resources and information possibly with
            criminal intent, without notice since they would appear as the
            registered owner.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about our services, which we think you may
            find interesting if you tell us that you wish this to happen.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            Vibecheck Technologies Pvt. Ltd. undertakes not to disclose the
            information provided by the customer to any person unless such
            action is necessary to the extent required by law and for regulatory
            purposes.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect. Vibecheck Technologies Pvt. Ltd.
            reserves all rights to the Website and its Contents. Some of the
            content &amp; features mentioned in the terms of use, disclaimers
            and privacy policy may not be live currently but all the terms and
            conditions mentioned in this document would also be applicable to
            those content and features once those services go live.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            We welcome your feedback concerning this Privacy Policy. Please send
            your comments, questions or desired clarifications to the vibecheck
            technologies at:
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <strong>Vibecheck Technologies Pvt. Ltd.</strong>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <strong>Reg. Off</strong>.: J5/58, First Floor, J 5 &amp; 6 Block,
            Rajouri Garden, Near Nehru Market, New Delhi - 110027
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <strong>Tel</strong>.: +91 9999-722799
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <strong>E-mail</strong>: <u>info@ GigPe™.com</u>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <u>
              <strong>Jurisdiction</strong>
            </u>
            <strong>:</strong>
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            If you choose to visit the website, your visit and any dispute over
            privacy is subject to this Policy and the website's terms of use. In
            addition to the foregoing, the laws of India shall govern any
            disputes arising under this Policy, and courts of Delhi only will
            have absolute jurisdiction over the subject matter involved.
          </p>
          <p
            style={{
              textAlign: 'justify',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '115%',
            }}
          >
            <br />
          </p>
          <p
            style={{
              textAlign: 'center',
              background: 'transparent',
              marginBottom: '0cm',
              lineHeight: '100%',
            }}
          ></p>
        </div>
      </div>
    </>
  );
}
