import React from 'react';
import hn from '../images/hn.png';
export default function NewCards() {
  return (
    <div>
      <div className="new-card-h">
        <hr className="fwhr"></hr>
        <h2 className="fwh2">WHY GigPe™ ?</h2>
      </div>
      <div className="newcard">
        <div className="newcards">
          <h2 style={{}}>Work,Earn & Learn</h2>
          <p>
            Work opportunities across various industries makes it easy to try &
            learn new things and get paid.
          </p>
        </div>
        <div className="newcards">
          <h2 style={{}}>Flexible Shifts</h2>
          <p>
            You’re in control of when and where you want to work. Choose daily
            or hourly gigs on your own terms.
          </p>
        </div>
        <div className="newcards">
          <h2 style={{}}>Career Building </h2>
          <p>
            You don’t have to have a resume to join GigPe™, but every job is an
            opportunity to create one.
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <img src={hn} className="hn"></img>
    </div>
  );
}
