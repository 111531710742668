import React from 'react'

export default function AboutUsBanner() {
    return (
        <div className="about-us-banner">
            <div className="about-us-banner-header">
   <p>TEAM THAT GOT YOUR BACK</p>
            </div>
            <div className="about-us-banner-content">
                <p>We are a team of hustlers, who started early, failed early, learnt from our mistakes and grew multifold in the process. As individuals we all have worked in various operational and management capacities across industries, in large multinationals as well as startups. We experienced this mismatch of demand and quality supply first hand and decided to do something about it ! India’s part-time work culture left much to be desired and that's where we come in, creating an ecosystem where temporary skilled workers can be hired , anytime, anywhere - ensuring complete employer satisfaction</p>
            </div>
        </div>
    )
}
