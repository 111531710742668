import React,{useEffect, useState} from 'react'
// const queryString = require('query-string');
import queryString from "query-string"
import axios from "axios";
import { BASE_URL } from "../config"



const VerificationPage = (props) => {
    
    const [verified, setVerified] = useState(false);
    const [message, setMessage] = useState('');
    useEffect(() => {
     const parsed=queryString.parse(props.location.search);

     const body={
         transaction_id:`${parsed.transaction_id}`,
         status:`${parsed.status}`
     };

     console.log(body)
     const config={
         headers:{
             'Accept':'Application/json'
         }
     };

        axios.post(`${BASE_URL}/api/admin/verify`, body, config)
        .then((res)=>{
           if(res.data.api_status===0){
                 setVerified(false);
                 setMessage(res.data.errors.message);
           }else{
setVerified(true);
setMessage(res.data.message);
           }
        }).catch(err=>{
            setVerified(false);
            setMessage(res.data.errors.message);
        })
    }, [])


    return (
        <div style={{marginTop:'150px'}}>
        <h1>{message}</h1>    
        </div>
    )
}

export default VerificationPage;