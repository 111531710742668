import React from 'react';

const text = '2022© Vibecheck Technologies Pvt. Ltd. ';

export default function Footer() {
  return (
    <></>
    // <div
    //   style={{
    //     backgroundColor: '#6543B1',
    //     marginTop: '15px',
    //     padding: '15px',
    //     textAlign: 'center',
    //     display: 'flex',
    //     justifyContent: 'center',
    //   }}
    // >
    //   <p style={{ color: 'white', margin: '0 auto' }}>{text}</p>
    // </div>
  );
}
