import React, { useState, useRef } from 'react'
import { Redirect } from "react-router-dom"
import logo from '../images/Milestone.png'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import cogoToast from 'cogo-toast';
import axios from "axios"
import MyVerticallyCenteredModal2 from "./MyVerticallyCenteredModal2"
import modalDone from "../images/modalDone.png"
import errorImg from "../images/errorImg.png"
import modal1 from "../images/modal1.png"
import * as moment from "moment"
import Moment  from "react-moment"
// import {Button from ""

import { BASE_URL } from "../config"

export default function Form() {

    const [isModalOpen, setModalIsOpen] = useState(false);

    const [isFormIncomplete, setIsFormIncomplete] = useState(false);
    const [name, setName] = useState('');
    const [work_email, setEmail] = useState('');
    const [contact_number, setPhone] = useState('');
    const [company_location, setLocation] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');
    const [client_message_id, setClientMessageId] = useState('');

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [error, setError] = useState(false);

    const otp = useRef('');
    const [partOne, setPartOne] = useState(false);

    const [desc, setDesc] = useState('');
    const [roles, setRoles] = useState([]);
    const [secondPart, setSecondPart] = useState(false);
    const [redir, setRedir] = useState(false);
    const [mrk, setMrk] = useState(false);
    const [opr, setOpr] = useState(false);
    const [prod, setProd] = useState(false);
    const [res, setRes] = useState(false);
    const [sal, setSal] = useState(false);
    const [rec, setRec] = useState(false);
    const [no_of_people_required, setnopr] = useState('');
    const [gig_locations, setgl] = useState('');
    const [address, setAddress] = useState('');

    const [start_date, setsd] = useState('');
    const [end_date, seted] = useState('');


    const onChangeHandler = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name === "name") {
            setName(value);
        } else if (name === "email") {
            setEmail(value);
        } else if (name === "phone") {
            setPhone(value);
        } else if (name === "location") {
            setLocation(value);
        } else if (name === "companyname") {
            setCompanyName(value);
        } else if (name === "industry") {
            setIndustry(value);
        } else if (name === "description") {
            setDesc(value);
        } else if (name === "nopr") {
            setnopr(value);
        } else if (name === "giglocations") {
            setgl(value);
        } else if (name === "address") {
            setAddress(value);
        } else if (name === "start_date") {
            setsd(value);
        } else if (name === "end_date") {
            seted(value);
        }

    }

    const onSubmit2 = (e) => {
        e.preventDefault();
        const body = {
            email: `${work_email}`,
            otp: `${otp.current.value}`
        };
        const headers = {
            'Accept': 'application/json'
        };
        axios.post(`${BASE_URL}/api/client-message-verify-email`, body, { headers })
            .then((res) => {
                console.log(res)
                if (res.data.api_status === 1) {

                    console.log("in63");
                    setModalIsOpen(false);
                    setIsSubmitted(true);
                    setError(false);
                } else {
                    setError(false);
                    setModalIsOpen(false);
                    setError(true);
                }

            })
            .catch(() => {
                cogoToast.error('Oops! Something went wrong')
            })
    }


    const onSubmit3 = (e) => {
        e.preventDefault();

        const body = {
            client_message_id: client_message_id
        };
        const headers = {
            'Accept': 'application/json'
        };
        axios.post(`${BASE_URL}/api/resend-email`, body, { headers })
            .then((res) => {

                if (res.data.api_status === 1) {
                    setError(false);
                    setModalIsOpen(true);
                } else {
                    cogoToast.error('Oops! Something went wrong')

                }
            })
            .catch((err) => cogoToast.error('Oops! Something went wrong')
            )
    }

    function MyVerticallyCenteredModal(props) {
        return (

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <section style={{ display: 'flex' }}>

                        <section className="modal-img-sect"><img src={modal1} className="modal-img" />
                            {/* <Button variant="primary" onClick={onSubmit3}>Resend Email</Button> */}
                        </section>
                        <div className="modal-info-sect">
                            <p>For security purposes, we need to verify your email address.</p>
                            <input type="password" className="modal-input" placeholder="Please enter the OTP" ref={otp} />
                            <br></br>
                            <button type='button' className="modal-btn" onClick={onSubmit2}>Verify Now</button>
                            <br></br>
                            <br></br>
                            <label>Yet not received the otp, click to resend</label>
                            <br></br>
                            <button type="button" className="modal-btn" onClick={onSubmit3}>Resend Email</button>
                            {/* <button type='button' className="modal-btn" onClick={onSubmit2}>Submit</button> */}
                        </div>
                    </section>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>

        );

    }


    function MyVerticallyCenteredModal3(props) {


        return (

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <section className="error-modal-sect" style={{ display: 'flex' }}>
                        <section className="error-modal-sect1">
                            <img src={errorImg} className="modal-img"></img>
                            <p style={{ color: '#042657', fontWeight: '600' }}>Oops !</p>
                        </section>
                        <div>
                            <p style={{ color: '#042657' }}>Enter the OTP you received on your email</p>
                            <input type="password" className="modal-input" placeholder="Please enter the OTP again" ref={otp} />
                            <br></br>
                            <div className="error-modal-sect-div">
                                <button type="button" className="modal-btn" onClick={onSubmit2}>Submit</button>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>

        );

    }


    const onSubmit = (e) => {
        e.preventDefault();

        if (name && work_email && company_location && contact_number && company_name && industry && address) {

            const data = {
                name: `${name}`,
                work_email: `${work_email}`,
                company_location: `${company_location}`,
                contact_number: `${contact_number}`,
                company_name: `${company_name}`,
                industry: `${industry}`,
                address: `${address}`
            };

            const headers = {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            };

            axios.post(`${BASE_URL}/api/client-message`, data, { headers })
                .then(result => {
                    console.log(result.data)
                    if (result.data.api_status === 1) {

                        setModalIsOpen(true);
                        setClientMessageId(result.data.data.client_message_id);

                    } else {
                        cogoToast.error('Oops! Something went wrong')
                    }
                }).catch(err => cogoToast.error('Oops! Something went wrong')
                )

        } else {
            setIsFormIncomplete(true);
            setTimeout(function () {
                setIsFormIncomplete(false);
            }, 2000);
        }
    }



    const onSubmit4 = (e) => {
        e.preventDefault();
console.log("in266")


// moment.calendarFormat()
        const st_dt = moment(`${start_date}`).format("YYYY-MM-DD");
        const e_dt = moment(`${end_date}`).format("YYYY-MM-DD");

console.log(st_dt,e_dt);

        const data = {
            client_message_id: parseInt(`${client_message_id}`),
            description: `${desc}`,
            job_categories: roles,
            start_date:`${st_dt}`,
            end_date:`${e_dt}`,
            no_of_people_required:`${no_of_people_required}`,
            gig_locations:`${gig_locations}`
        };

        if (roles.length > 0 && desc && gig_locations && no_of_people_required && start_date && end_date) {
            const headers = {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            };
            
            axios.patch(`${BASE_URL}/api/client-message-add-on`, data, { headers })
                .then(res => {

                    if (res.data.api_status === 1) {
                        setSecondPart(true);
                        setTimeout(function () {
                            setRedir(true);
                        }, 1500);

                    } else {
                        console.log(res.data)
                        cogoToast.error('Oops! Something went wrong')
                    }

                })
                .catch(err => {cogoToast.error('Oops! Something went wrong')
            console.log(err)
            }
                );
        } else {
            setIsFormIncomplete(true);
            setTimeout(function () {
                setIsFormIncomplete(false);
            }, 2000);
        }

    }




    const onSecFormHandler = (e) => {
        const name = e.target.name;
console.log(name);
        if (name === 'Marketing') {
            if (mrk) {
                setRoles(roles.filter(item => item.name !== `${name}`))
            } else {
                setRoles([{ "name": `${name}` }, ...roles])
            }
            setMrk(!mrk);

        } else if (name === 'Operations') {

            if (opr) {
                setRoles(roles.filter(item => item.name !== `${name}`))
            } else {
                setRoles([{ "name": `${name}` }, ...roles])
            }
            setOpr(!opr);

        } else if (name === 'Products') {

            if (prod) {
                setRoles(roles.filter(item => item.name !== `${name}`))
            } else {
                setRoles([{ "name": `${name}` }, ...roles])

            }
            setProd(!prod);

        } else if (name === 'Research') {

            if (res) {
                setRoles(roles.filter(item => item.name !== `${name}`))

            } else {
                setRoles([{ "name": `${name}` }, ...roles])

            }
            setRes(!res);

        } else if (name === 'Sales') {

            if (sal) {
                setRoles(roles.filter(item => item.name !== `${name}`))

            } else {
                setRoles([{ "name": `${name}` }, ...roles])

            }
            setSal(!sal);

        } else if (name === 'Creative Pool') {

            if (rec) {
                setRoles(roles.filter(item => item.name !== `${name}`))

            } else {
                setRoles([{ "name": `${name}` }, ...roles])

            }
            setRec(!rec);

        }

    }


    function MyVerticallyCenteredModal4(props) {
        return (

            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>

                    <section className="form-completed-modal-sect">
                        <img src={modalDone}></img>
                        <h2>Thank you!</h2>
                        <div className="form-completed-modal-sect-div" style={{ width: '100%' }}>
                            <p style={{ width: '100%' }}>We have successfully received your request.</p>
                            <p style={{ width: '100%' }}>We will get back to you shortly.</p>
                        </div>
                    </section>

                </Modal.Body>
            </Modal>

        );

    }

    if (redir) {
        return <Redirect push to="/" />
    }


    return (

        <div className="area">
            <MyVerticallyCenteredModal show={isModalOpen} onHide={() => setModalIsOpen(false)} />

            <MyVerticallyCenteredModal2 show={isSubmitted} setIsSubmitted={setIsSubmitted} setPartOne={setPartOne} />

            <MyVerticallyCenteredModal3 show={error} onHide={() => setError(false)} />
            <MyVerticallyCenteredModal4 show={secondPart} onHide={() => setSecondPart(false)} />


            {!partOne &&

                <div className="form-pos">
                    <img className="milestone" src={logo} alt="milestone"></img>
                    <form onSubmit={onSubmit}>
                        <div className="forms">
                            <br></br>
                            <h1>Welcome!<br></br>
                                Let's get your job done!
                            </h1>
                            <p style={{ color: 'orangered' }} >You're doing so much, relax and let us help!</p>
                            <div>
                                <p></p>
                                <p></p>
                                <div className="firstTwo" style={{ width: '90%' }}>
                                    <section>
                                        <p className="input-names">Full Name</p>
                                        <input className="fill first"
                                            type="text"
                                            placeholder="Your full name"
                                            name="name"
                                            value={name}
                                            onChange={onChangeHandler}
                                        >
                                        </input>
                                        {!name && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your company name</p>}
                                    </section>
                                    <section className="second-sect">
                                        <p className="input-names">Company Name</p>
                                        <input type="text" className="fill second"
                                            placeholder="Your company name"
                                            name="companyname"
                                            value={company_name}
                                            onChange={onChangeHandler}
                                        >
                                        </input>
                                        {!company_name && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your username</p>}

                                    </section>
                                </div>
                                <p></p>
                                <p className="input-names">Email</p>
                                <input className="fill"
                                    type="email"
                                    placeholder="Your Work Email"
                                    name="email"
                                    value={work_email}
                                    onChange={onChangeHandler}></input>
                                {!work_email && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your email</p>}


                                <p></p>
                                <p className="input-names">Phone Number</p>
                                <input className="fill"
                                    type="tel"
                                    placeholder="Your 10 digit phone number"
                                    pattern="(6|7|8|9)\d{9}"
                                    minLength={10}
                                    maxLength={10}
                                    value={contact_number}
                                    name="phone"
                                    onChange={onChangeHandler}></input>
                                {!contact_number && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your contact number</p>}
                                <p></p>
                                <p className="input-names">Address</p>
                                <input type="text" className="fill"
                                    name="address"
                                    value={address}
                                    placeholder="Your address"
                                    onChange={onChangeHandler}
                                />
                                {!address && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your address</p>}
                                <p></p>
                                <p className="input-names">City</p>
                                <input className="fill"
                                    type="text"
                                    placeholder="Where are you from"
                                    value={company_location}
                                    name="location"
                                    onChange={onChangeHandler}></input>
                                {!company_location && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter your location</p>}
                                <p></p>
                                <p className="input-names">Industry</p>
                                <input type="text" className="fill"
                                    name="industry"
                                    value={industry}
                                    onChange={onChangeHandler}
                                    placeholder="Please mention your industry"
                                />
                                {!industry && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please mention your industry</p>}
                                <p></p>
                                <br></br>

                                <button className="otp" type="submit">Request OTP</button>

                            </div>

                        </div>


                    </form>
                </div>

            }


            {partOne &&



                <div className="formbtns">


                    <img className="milestone" src={logo}></img>


                    <div style={{ marginTop: '25px' }} className="mob-form">
                        <div className="pos">
                            <h1>Finish your requirements</h1>
                            <p style={{ color: 'grey' }}>Select your industry</p>
                            {/* <ul className="ks-cboxtags">


                 <div className="boxtags-div">
                                                 <li ><input type="checkbox" id="one" value="Marketing" onChange={onSecFormHandler} /><label for="one">Marketing</label></li>

                                                 <li ><input type="checkbox" id="two" value="Operations" onChange={onSecFormHandler} /><label for="two">Operations</label></li>

                                                 <li ><input type="checkbox" id="three" value="Products" onChange={onSecFormHandler} /><label for="three">Products</label></li>
                 </div>
                                                 <br></br>
                 <div className="boxtags-div">
                                                 <li ><input type="checkbox" id="four" value="Research" onChange={onSecFormHandler} /><label for="four">Research</label></li>

                                                 <li  ><input type="checkbox" id="five" value="Sales" onChange={onSecFormHandler} /><label for="five">Sales</label></li>

                                                 <li ><input type="checkbox" id="six" value="Recruiting" onChange={onSecFormHandler} /><label for="six">Recruiting</label></li>
                 </div>
                                             </ul>  */}



                            {/* 
                            <div class="row">
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li" ><input type="checkbox" id="one" value="Marketing" onChange={onSecFormHandler} /><label for="one">Marketing</label></li>
                                </div>
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li" ><input type="checkbox" id="two" value="Operations" onChange={onSecFormHandler} /><label for="two">Operations</label></li>

                                </div>
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li" ><input type="checkbox" id="three" value="Products" onChange={onSecFormHandler} /><label for="three">Products</label></li>

                                </div>
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li"><input type="checkbox" id="four" value="Research" onChange={onSecFormHandler} /><label for="four">Research</label></li>

                                </div>
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li"><input type="checkbox" id="five" value="Sales" onChange={onSecFormHandler} /><label for="five">Sales</label></li>

                                </div>
                                <div class="col-md-4 col-sm-5">
                                    <li className="form-li"><input type="checkbox" id="six" value="Recruiting" onChange={onSecFormHandler} /><label for="six">Recruiting</label></li>
                                </div>
                            </div> */}


                            <div className="categories">

                                <div style={{display:'flex'}}>
                                <div>    
                                    <button className={mrk ? "clicked-category-btn" : "category-btn"} name="Marketing" onClick={onSecFormHandler}>Marketing</button>
                                </div>
                                <div>
                                    <button className={opr ? "clicked-category-btn category-btn-2" : "category-btn category-btn-2"}  name="Operations" onClick={onSecFormHandler}>Operations</button>
                                </div>
                                <div>
                                    <button className={prod ? "clicked-category-btn category-btn-2" :"category-btn category-btn-2"} name="Products" onClick={onSecFormHandler}>Products</button>
                                </div>
                                </div>
                                <div style={{display:'flex', marginTop:'15px'}}>
                                <div>
                                    <button className={res ? "clicked-category-btn " :"category-btn"}   name="Research" onClick={onSecFormHandler}>Research</button>
                                </div>
                                <div>
                                    <button className={sal ? "clicked-category-btn category-btn-2" :"category-btn category-btn-2"} name="Sales" onClick={onSecFormHandler}>Sales</button>
                                </div>
                                <div>
                                    <button className={rec ? "clicked-category-btn category-btn-2" :"category-btn category-btn-2"}  name="Creative Pool" onClick={onSecFormHandler}>Creative Pool</button>
                                </div>

                                </div>
                              

                                {roles.length === 0 && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please select at least one job role/</p>}

                            </div>



                        <div className="categories-mob">

                            <div style={{ display: 'flex' }}>
                                <div>
                                    <button className={mrk ? "clicked-category-btn" : "category-btn"} name="Marketing" onClick={onSecFormHandler}>Marketing</button>
                                </div>
                                <div>
                                    <button className={opr ? "clicked-category-btn category-btn-2" : "category-btn category-btn-2"} name="Operations" onClick={onSecFormHandler}>Operations</button>
                                </div>


                            </div>

                            <div style={{ display: 'flex', marginTop:'20px' }}>
                                <div>
                                    <button className={prod ? "clicked-category-btn " : "category-btn category-btn-2"} name="Products" onClick={onSecFormHandler}>Products</button>
                                </div>
                                <div>
                                    <button className={res ? "clicked-category-btn category-btn-2" : "category-btn category-btn-2"} name="Research" onClick={onSecFormHandler}>Research</button>
                                </div>


                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div>
                                    <button className={sal ? "clicked-category-btn " : "category-btn category-btn-2"} name="Sales" onClick={onSecFormHandler}>Sales</button>
                                </div>
                                <div>
                                    <button className={rec ? "clicked-category-btn category-btn-2" : "category-btn category-btn-2"} name="Creative Pool" onClick={onSecFormHandler}>Creative Pool</button>
                                </div>

                            </div>
                            {roles.length === 0 && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please select at least one job role/</p>}

                        </div>





                            <div className="firstTwo" style={{marginTop:'15px'}}>

                                <section>
                                    <p className="input-names">No of people required</p>
                                    <input className="fill"
                                        type="text"
                                        name="nopr"
                                        placeholder="Tell us here"
                                        value={no_of_people_required}
                                        onChange={onChangeHandler}
                                    >
                                    </input>
                                    {!no_of_people_required && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter the number of people required</p>}
                                </section>
                                <section className="second-sect">
                                    <p className="input-names">Gig Locations(Type Multiple)</p>
                                    <input type="text" className="fill"
                                        placeholder="Gig Locations"
                                        name="giglocations"
                                        value={gig_locations}
                                        onChange={onChangeHandler}
                                    >
                                    </input>
                                    {!gig_locations && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter the gig locations</p>}
                                </section>

                            </div>


                            <div className="firstTwo" style={{ marginTop: '20px' }} >

                                <section>
                                    <p className="input-names">Start Date</p>
                                    <input type="date"
                                        placeholder="yyyy-mm-dd" className="fill"
                                        onChange={onChangeHandler}
                                        name="start_date"
                                    ></input>
                                    {!start_date && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter the start date</p>}
                                </section>

                                <section className="second-sect">
                                    <p className="input-names">End Date</p>
                                    <input type="date" 
                                    placeholder="yyyy-mm-dd" className="fill"
                                        onChange={onChangeHandler}
                                        name="end_date"
                                    ></input>
                                    {!end_date && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please enter the end date</p>}
                                </section>

                            </div>

                            <p className="t-area-p second-sect" style={{ color: 'rgb(80, 76, 76)', marginTop: '20px' }}>Additional Information</p>

                            <textarea maxLength="250" value={desc} className="t-area" type="text" placeholder="Describe the responsibilities of the job required, work experience, skills, or education." name="description" onChange={onChangeHandler}></textarea>


                            {!desc && isFormIncomplete && <p className="error-p" style={{ color: 'red' }}>Please provide some additional information</p>}

                            <br></br>

                            <button className="t-area-button" type="submit" onClick={onSubmit4} >GET A QUOTE </button>
                        </div>
                    </div>
                </div>}

            
        </div>


    )
}

