import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cogoToast from 'cogo-toast';

import { BASE_URL } from '../config';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from 'react-router-dom';

import modalDone from '../images/modalDone.png';

import './styles.css';

// const  GigPe™Form = (props) => {

//     return (
//         <>
//             <form name="support-form" onSubmit={(e) => props.handleSubmit(e)} >

//                 <div className="form-div">
//                     <div className="input-container half-width">
//                         <label htmlFor="name">Your Name</label>
//                         <p class="first-p"><input placeholder="Your Name" type="text" name="name" onChange={props.handleChange} ></input></p>
//                         {!company_name && isFormIncomplete && <p style={{ color: 'red' }}>Please enter your username</p>}

//                     </div>
//                     <div className="input-container half-width">
//                         <label htmlFor="contact">Contact Number</label>
//                         <p class="first-p"><input placeholder="Contact Number" type="number" name="contact" onChange={props.handleChange}></input></p>
//                     </div>
//                 </div>

//                 <br></br>

//                 <div>
//                     <div className="input-container">
//                         <label htmlFor="email">Your Email</label>
//                         <p><input className="full-width" placeholder="example@gmail.com" type="text" name="email" onChange={props.handleChange}></input></p>
//                     </div>
//                 </div>

//                 <br></br>

//                 <div>
//                     <div className="input-container">
//                         <label htmlFor="message">Message</label>
//                         <p><textarea className="full-width" rows="5" placeholder="Your message here" type="text" name="message" onChange={props.handleChange}></textarea></p>
//                     </div>
//                 </div>

//                 <br>
//                 </br>
//                 <div>
//                     <button type="button" className="youthButton" type="button" onClick={props.handleSubmit} >
//                         Get In Touch
//                     </button>
//                 </div>

//             </form>
//         </>
//     )
// }

function ContactUs() {
  const [formToDisplay, setFormToDisplay] = useState(' GigPe™');
  const [secondPart, setSecondPart] = useState(false);
  const [redir, setRedir] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/locations`).then((res) => {
      setCities(res.data.data.locations);
    });
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    form_type: ' GigPe™ Support',
    name: '',
    email: '',
    contact: '',
    message: '',
    company_name: '',
    city: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.name &&
      formData.email &&
      formData.contact &&
      formData.message
    ) {
      const data = {
        name: `${formData.name}`,
        email: `${formData.email}`,
        contact_number: `${parseInt(formData.contact)}`,
        message: `${formData.message}`,
        form_type: `${formData.form_type}`,
      };

      const headers = {
        Accept: 'application/json',
      };

      axios
        .post(`${BASE_URL}/api/contact-us-mail`, data, { headers })
        .then((res) => {
          console.log(res, data);
          if (res.data.api_status === 1) {
            setSecondPart(true);
            setTimeout(function () {
              setRedir(true);
            }, 1500);
          } else {
            cogoToast.error(`${res.data.errors.message}`);
          }
        })
        .catch((err) => cogoToast.error('Oops! Something went wrong'));
    } else {
      setIsFormIncomplete(true);
      setTimeout(function () {
        setIsFormIncomplete(false);
      }, 2000);
    }
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();

    if (
      formData.name &&
      formData.email &&
      formData.contact &&
      formData.message &&
      formData.company_name
    ) {
      const data = {
        name: `${formData.name}`,
        email: `${formData.email}`,
        contact_number: `${parseInt(formData.contact)}`,
        message: `${formData.message}`,
        form_type: `${formData.form_type}`,
        company_name: `${formData.company_name}`,
      };

      const headers = {
        Accept: 'application/json',
      };

      axios
        .post(`${BASE_URL}/api/contact-us-mail`, data, { headers })
        .then((res) => {
          console.log(res, data);
          if (res.data.api_status === 1) {
            setSecondPart(true);
            setTimeout(function () {
              setRedir(true);
            }, 1500);
          } else {
            cogoToast.error(`${res.data.errors.message}`);
          }
        })
        .catch((err) => cogoToast.error('Oops! Something went wrong'));
    } else {
      setIsFormIncomplete(true);
      setTimeout(function () {
        setIsFormIncomplete(false);
      }, 2000);
    }
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();

    if (
      formData.name &&
      formData.email &&
      formData.contact &&
      formData.message &&
      formData.city
    ) {
      const data = {
        name: `${formData.name}`,
        email: `${formData.email}`,
        contact_number: `${parseInt(formData.contact)}`,
        message: `${formData.message}`,
        form_type: `${formData.form_type}`,
        city: `${formData.city}`,
      };

      const headers = {
        Accept: 'application/json',
      };

      axios
        .post(`${BASE_URL}/api/contact-us-mail`, data, { headers })
        .then((res) => {
          console.log(res, data);
          if (res.data.api_status === 1) {
            setSecondPart(true);
            setTimeout(function () {
              setRedir(true);
            }, 1500);
          } else {
            cogoToast.error(`${res.data.errors.message}`);
          }
        })
        .catch((err) => cogoToast.error('Oops! Something went wrong'));
    } else {
      setIsFormIncomplete(true);
      setTimeout(function () {
        setIsFormIncomplete(false);
      }, 2000);
    }
  };

  function MyVerticallyCenteredModal4(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <section className="form-completed-modal-sect">
            <img src={modalDone}></img>
            <h2>Thank you!</h2>
            <div className="form-completed-modal-sect-div">
              <p>We have successfully received your request.</p>
              <p>We will get back to you shortly.</p>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    );
  }

  if (redir) {
    return <Redirect push to="/" />;
  }

  return (
    <div className="wrapper">
      <MyVerticallyCenteredModal4
        show={secondPart}
        onHide={() => setSecondPart(false)}
      />

      <div className="tab-container">
        <div className="tab-buttons-container">
          <div className="tab-buttons-items">
            <button
              className={
                formToDisplay === ' GigPe™'
                  ? 'tab-buttons-selected'
                  : 'tab-buttons'
              }
              type="button"
              onClick={() => {
                setFormToDisplay(' GigPe™');
                setFormData({ ...formData, form_type: ' GigPe™ Support' });
              }}
            >
              GigPe™ Support
            </button>
            <button
              className={
                formToDisplay === 'business'
                  ? 'tab-buttons-selected'
                  : 'tab-buttons'
              }
              type="button"
              onClick={() => {
                setFormToDisplay('business');
                setFormData({ ...formData, form_type: 'Business' });
              }}
            >
              {' '}
              For Businesses
            </button>
            <button
              className={
                formToDisplay === 'vendors'
                  ? 'tab-buttons-selected'
                  : 'tab-buttons'
              }
              type="button"
              onClick={() => {
                setFormToDisplay('vendors');
                setFormData({ ...formData, form_type: 'Vendors/Contractors' });
              }}
            >
              {' '}
              Vendors/Contractors
            </button>
          </div>
        </div>
      </div>
      <br></br>
      <div className="support-text">
        <div
          style={{ display: formToDisplay === ' GigPe™' ? 'block' : 'none' }}
          className="contents"
        >
          <h3 style={{ color: '#042657', fontWeight: '600', fontSize: '21px' }}>
            {' '}
            Vibecheck Technologies Pvt. Ltd. SUPPORT{' '}
          </h3>
          <p className="graytext">
            {' '}
            Facing an issue? Don't worry, tell us here and we will get back to
            you.{' '}
          </p>
        </div>
        <div
          style={{ display: formToDisplay === 'business' ? 'block' : 'none' }}
        >
          <h3 style={{ color: '#042657', fontWeight: '600', fontSize: '21px' }}>
            FOR BUSINESSES
          </h3>
          <p className="graytext">
            Please tell us your query here and one of our team members will
            contact you shortly.
          </p>
        </div>
        <div
          style={{ display: formToDisplay === 'vendors' ? 'block' : 'none' }}
        >
          <h3 style={{ color: '#042657', fontWeight: '600', fontSize: '21px' }}>
            FOR VENDORS/CONTRACTORS
          </h3>
          <p className="graytext">
            If you want to partner with us, please write to us in detail about
            it and we will get back to you.
          </p>
        </div>
      </div>

      <div className="formContainer">
        <form
          onSubmit={
            formToDisplay === ' GigPe™'
              ? handleSubmit
              : formToDisplay === 'business'
              ? handleSubmit2
              : handleSubmit3
          }
        >
          <section
            style={{
              display: formToDisplay === ' GigPe™' ? 'block' : 'none',
            }}
          >
            <div className="form-div">
              <div className="input-container half-width">
                <label htmlFor="name">Your Name</label>
                <p class="first-p">
                  <input
                    placeholder="Your Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                  ></input>
                </p>

                {!formData.name && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your name
                  </p>
                )}
              </div>

              <div className="input-container half-width">
                <label htmlFor="contact">Contact Number</label>
                <p class="first-p">
                  <input
                    type="tel"
                    placeholder="Your contact number"
                    name="contact"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.contact && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your contact number
                  </p>
                )}
              </div>
            </div>

            <br></br>

            <div>
              <div className="input-container">
                <label htmlFor="email">Your Email</label>
                <p>
                  <input
                    className="full-width"
                    placeholder="example@gmail.com"
                    type="text"
                    name="email"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.email && isFormIncomplete && (
                  <p style={{ color: 'red' }}>Please enter your email</p>
                )}
              </div>
            </div>
            <br></br>
          </section>

          <section
            style={{ display: formToDisplay === 'business' ? 'block' : 'none' }}
          >
            <div className="form-div">
              <div className="input-container half-width">
                <label htmlFor="name">Your Name</label>
                <p class="first-p">
                  <input
                    placeholder="Your Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.name && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your name
                  </p>
                )}
              </div>
              <div className="input-container half-width">
                <label htmlFor="company_name">Company Name</label>
                <p className="first-p">
                  <input
                    placeholder="Company Name"
                    type="text"
                    name="company_name"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.company_name && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your company name
                  </p>
                )}
              </div>
            </div>
            <div className="form-div" style={{ marginTop: '20px' }}>
              <div className="input-container half-width">
                <label htmlFor="email">Your Work Email</label>
                <p className="first-p">
                  <input
                    type="email"
                    placeholder="Your email address"
                    name="email"
                    onChange={handleChange}
                  />
                </p>
                {!formData.email && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your company name
                  </p>
                )}
              </div>
              <div className="input-container half-width">
                <label htmlFor="contact">Contact Number</label>
                <p className="first-p">
                  <input
                    type="tel"
                    placeholder="Your contact number"
                    name="contact"
                    onChange={handleChange}
                  ></input>
                </p>
              </div>
            </div>
          </section>

          <section
            style={{ display: formToDisplay === 'vendors' ? 'block' : 'none' }}
          >
            <div className="form-div">
              <div className="input-container half-width">
                <label htmlFor="name">Your Name</label>
                <p class="first-p">
                  <input
                    placeholder="Your Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                  ></input>
                </p>

                {!formData.name && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your name
                  </p>
                )}
              </div>
              <div className="input-container half-width">
                <label htmlFor="contact">Contact Number</label>
                <p class="first-p">
                  <input
                    type="tel"
                    placeholder="Your contact number"
                    name="contact"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.contact && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your contact number
                  </p>
                )}
              </div>
            </div>
            <div className="form-div">
              <div className="input-container half-width">
                <label htmlFor="email">Your Email</label>
                <p class="first-p">
                  <input
                    placeholder="Your Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                  ></input>
                </p>

                {!formData.email && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your email
                  </p>
                )}
              </div>
              <div className="input-container half-width">
                <label htmlFor="contact">City</label>
                <p class="first-p">
                  <input
                    type="text"
                    placeholder="Your city"
                    name="city"
                    onChange={handleChange}
                  ></input>
                </p>
                {!formData.city && isFormIncomplete && (
                  <p style={{ color: 'red', fontSize: '11px' }}>
                    Please enter your city
                  </p>
                )}
              </div>
            </div>
          </section>

          <div>
            <div className="input-container">
              <label htmlFor="message">Message</label>
              <p>
                <textarea
                  className="full-width"
                  rows="5"
                  placeholder="Your message here"
                  type="text"
                  maxLength="250"
                  name="message"
                  onChange={handleChange}
                ></textarea>
              </p>
              {!formData.message && isFormIncomplete && (
                <p style={{ color: 'red' }}>Please enter your query</p>
              )}
            </div>
          </div>

          <br></br>

          <button className="youthButton" type="submit" onClick={handleSubmit}>
            Get In Touch
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
