import React, { Component } from 'react';
import Form from '../components/Form';
import Cards1 from '../components/Cards1';
import Slider from '../components/Slider';

export default function FormpageA() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Form />
      {/* <Slider/> */}
      <Cards1 />
    </div>
  );
}
