import React from 'react';

export default function TandC() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ margin: '10%', marginTop: '120px' }}>
        <p
          style={{
            margin: '0cm',
            marginBottom: '.0001pt',
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          <u>
            <strong className="terms-heading">Terms of Use – GigPe™</strong>
          </u>
        </p>
        <div className="terms-text" style={{ marginTop: '50px' }}>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'center',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>By using&nbsp;</span>
            <u>
              <span style={{ color: 'blue' }}>http://www. GigPe™.com</span>
            </u>
            <span style={{ color: 'black' }}>
              , (Vibecheck Technologies Pvt. Ltd. Online Solutions Private
              Limited, the "Site" or We or Us) You (User, reader, visitor)
              accept and will be bound by the terms and conditions. These terms
              and conditions may be updated time to time without prior notice.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              If you do not wish to be bound by these terms and conditions, you
              may not use this site.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Copyright, Licenses and Content Submissions
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              The articles, tutorials, projects and other content on this site
              is copyright of the site and its authors. You may read down and
              use the published content for your personal and educational use.
              The content may not be used for commercial purposes. However, you
              may not re-publish the content without prior written permission
              from us.&nbsp;
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Use of the Site
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <u>
              <span style={{ color: 'blue' }}>http://www. GigPe™.com</span>
            </u>
            <span style={{ color: 'blue' }}>&nbsp;</span>
            <span style={{ color: 'black' }}>
              is a free resource online community. Any body can share their
              views, ideas, code, articles, tips, projects or other related
              material on the site. Use the site on your own risk. Vibecheck
              Technologies Pvt. Ltd. does not guarantee or warrant accuracy and
              reliability of data and information published on the site. The
              site takes no responsibility of direct or indirect loss or any
              kind of harm to its users by other users. The site also doesn't
              take responsibility of infected files or source code with any kind
              of infection or viruses, worms, Trojan horses.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  By Using this website, you agree that you will not
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <ul
            style={{
              listStyleType: 'undefined',
              marginLeft: '4.199999999999999px',
            }}
          >
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Post any information, data, text, or images, or other material
                that is unlawful, harmful, threatening, abusive, harassing,
                vulgar, obscene, libellous or otherwise objectionable that may
                invade another's right of privacy or publicity; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Post any Material that you do not have a right to reproduce. If
                you publish other authors’ work, you must have a written
                permission of the author of that material; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Delete any author attributions, legal notices or proprietary
                designations or labels that you upload to any communication
                feature; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Upload any material copyright, trademark, patent, or proprietary
                rights of any third party; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Post any material, which can harm our visitors or authors; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Use our author's or member’s emails (published on their request
                in their profile) to send junk mail, Spam, or chain letters or
                without their permission; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Violate any applicable local, state, national or international
                law; and
              </span>
            </li>
            <li>
              <span
                style={{
                  color: 'black',
                }}
              >
                Post any information or images on discussion forums that may
                harm any body by any means or unlawful, harmful, threatening,
                abusive, harassing, vulgar, obscene, libellous, or otherwise
                objectionable that may invade another's right of privacy or
                publicity.
              </span>
            </li>
          </ul>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    lineHeight: '115%',
                    color: 'black',
                  }}
                >
                  Authors of Articles and Codes on GigPe™
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,
                  lineHeight: '115%',

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    lineHeight: '115%',
                    color: 'black',
                  }}
                >
                  <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              As an author, you agree that the site visitors are free to use
              your code or article for their reference and can use the code in
              their own programs freely and without restriction (UNLESS YOU HAVE
              THE PERMISSION IN YOUR CODE ITSELF) and may distribute compiled
              versions of the code freely and without restriction. By submitting
              your article or source code, you agree that you are the original
              owner (UNLESS YOU SPECIFY THE REFERENCES AND HAVE A WRITTEN
              PERMISSION OF THE ORIGINAL AUTHOR) of the article or code or
              otherwise has the right to redistribute it freely. Vibecheck
              Technologies Pvt. Ltd. takes no responsibility, no liability for
              disputes regarding ownership, copyright, or trademarks of the code
              uploaded to this site. However, by submitting your article or
              source code, you grant to Vibecheck Technologies Pvt. Ltd. , a
              nonexclusive, worldwide license to link to, reproduce, distribute,
              adapt, perform, display and sub license the submitted code or
              content.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              Vibecheck Technologies Pvt. Ltd. reserves the right to reject any
              submission, to alter submissions, and to remove a submission from
              the site that had previously been posted at any time without any
              previous notification. However once published article or code may
              not be removed from the site.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Contest and Prizes
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              Vibecheck Technologies Pvt. Ltd. may start or stop contests and
              prizes without any prior notification. Winners of contest and
              prizes are selected based on the article/code contents,
              usefulness, downloads, freelancers’ performance, clients’
              feedback, judge’s decision and other factors. Vibecheck
              Technologies Pvt. Ltd. Pvt. Ltd. Online Solutions Private Limited
              reserves all the rights to determine which entries are winners as
              well as to cancel the contest or prizes without any prior notice.
              Vibecheck Technologies Pvt. Ltd. Online Solutions Private Limited
              will try to send winner prizes as soon as possible. Winners are
              responsible to send their address within a week. We take no time
              guarantee for deliverance of the same outside the territorial
              limits of India, as we have no control on the posting service
              outside the India.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Links to third party sites
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              Third party site links on this site are only for your convenience
              and we have no responsibility and control on third party links. If
              any link is misleading or changed or removed, we are not
              responsible for that.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Trademarks
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              Google, AdSense, AdWords and other Google products referenced on
              this site are registered trademarks of Google. Other product and
              company names mentioned herein may be the trademarks of their
              respective owners.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 17,
                    color: 'black',
                  }}
                >
                  Copyright Notice
                </span>
              </u>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 17,

                  color: 'black',
                }}
              >
                :
              </span>
            </strong>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span
              style={{
                fontSize: 17,

                color: 'black',
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
              lineHeight: '115%',
            }}
          >
            <span style={{ color: 'black' }}>
              Permission to use Vibecheck Technologies Pvt. Ltd. Online
              Solutions Private Limited’s contents may be granted only after
              receiving a written request to do so, that too on the discretion
              of vibecheck technologies ’s Management.
            </span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'justify',
            }}
          >
            <span style={{ color: 'black' }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: '0cm',
              marginBottom: '.0001pt',
              fontSize: 16,
              textAlign: 'center',
            }}
          >
            <span style={{ color: 'black' }}>***********</span>
          </p>
        </div>
      </div>
    </>
  );
}
