import React from 'react';
import Crises from '../components/Crises';
import Features from '../components/Features';
import Slider from '../components/Slider';
import NewCards from '../components/NewCards';
export default function Covid() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Crises />
      <Features />
      {/* <Slider/> */}
      <NewCards />
    </div>
  );
}
