import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import hand from '../images/Hnad.png';

export default class Patch extends Component {
  render() {
    return (
      <div className="patch1">
        <form className="p-form">
          <br></br>
          <p>Take your step now to scale with GigPe™!</p>
          <br></br>
          <h1>
            Let's get your job done<br></br> <span>on-demand!</span>{' '}
          </h1>

          <div className="getstartedButton">
            <Link
              className="youthButton_or"
              to="/formpage-a"
              style={{
                background: '#042657',
                color: 'white',
                borderRadius: '30px',
                textDecoration: 'none !important',
              }}
            >
              {' '}
              Get Started
            </Link>
          </div>
        </form>
        <div className="handd">
          <img height="200px" src={hand} alt="handd"></img>
        </div>
      </div>
    );
  }
}
