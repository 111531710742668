import React, { useState } from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { Link, Redirect } from 'react-router-dom';
// import y from '../images/y-foot.png';
// import gp from '../images/playstore2.jpeg';

export default function Last() {
  return (
    <div className="Last-div">
      <div className="last1">
        <span className={'logo'} style={{ fontSize: '37px' }}>
          GigPe™
        </span>
        <p className="primaryCol">
          <small>
            The core idea of GigPe™ is to bridge the gap between the demand for
            temporary staffing and supply by providing skilled, relevant
            workforce to employers and creating wholesome development
            opportunities for part-time workers.
          </small>
        </p>
      </div>
      <div className="last2">
        <h2 className="icon-head">Solutions</h2>
        <p className="p-link">
          {' '}
          <a href="/find-work">Find Work</a>
        </p>
        <p className="p-link">
          {' '}
          <a href="/">Business</a>
        </p>
        <p className="p-link">
          {' '}
          <a href="/covid-19-support">Covid-19 Support</a>
        </p>
        <p className="p-link" style={{ fontWeight: 'bold' }}>
          {' '}
          <a href="/yskills">SkillPe</a>
          <span>
            <button className="comingSoon"> Coming Soon</button>
          </span>
        </p>
      </div>
      <div className="last3">
        <h2 className="icon-head">Company</h2>
        <Link style={{ textDecoration: 'none', color: '#6543B1' }} to="/about">
          <p className="p-link"> About Us</p>
        </Link>
        <p className="p-link">
          <Link style={{ textDecoration: 'none' }} to="/contact-us">
            Contact Us
          </Link>
        </p>
        <p className="p-link">
          <Link style={{ textDecoration: 'none' }} to="/faq">
            FAQs
          </Link>
        </p>
        <p className="p-link">
          <Link style={{ textDecoration: 'none' }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </p>
        <p className="p-link">
          <Link style={{ textDecoration: 'none' }} to="/t&c">
            Terms and Conditions
          </Link>
        </p>
      </div>

      {/* <div className="last4">
        <div>
          <h2 className="icon-head">We are social</h2>
          <br></br>

          <h1 style={{ marginTop: '-7%' }} className="icons">
            <a href="https://instagram.com/ GigPe™?igshid=14dg0q8lc3gwt">
              <FaInstagramSquare
                className="fainsta"
                style={{ color: '#D2D4D6' }}
              />
              &nbsp;
            </a>
            <a href="https://www.facebook.com/ GigPe™/">
              {' '}
              <FaFacebookSquare
                className="fainsta"
                style={{ color: '#D2D4D6' }}
              />
              &nbsp;{' '}
            </a>
            <a href="https://mt.linkedin.com/company/ GigPe™">
              {' '}
              <FaLinkedin className="fainsta" style={{ color: '#D2D4D6' }} />
              &nbsp;{' '}
            </a>
            <a href="https://twitter.com/ GigPe™?s=21">
              {' '}
              <FaTwitter
                className="fainsta"
                style={{ color: '#D2D4D6' }}
              />{' '}
              &nbsp;
            </a>
          </h1>
        </div>
        <br />
        <div style={{ marginTop: '15px' }}>
          <h2 className="icon-head" style={{ marginBottom: '0px !important' }}>
            Download
          </h2>
          <Link
            to={{
              pathname:
                'https://play.google.com/store/apps/details?id=com. GigPe™.parttimejobs',
            }}
            target="_blank"
          >
            {' '}
            <img
              style={{ marginTop: '10px' }}
              alt="playstore"
              height="40px"
              width="150px"
              src={gp}
            ></img>
          </Link>
        </div>
        <br></br>
      </div> */}
    </div>
  );
}
