import React from 'react'
import mask from '../images/mask.png'
import virus from '../images/virus.png'
import covidweb from "../images/covidwebfinal.jpg"
import covidmob from "../images/covidmob.jpg"

export default function Crises() {
    return (
        <div>
            <div className="crises">
                <hr style={{
                    margin: '0 auto',
                    marginBottom: '20px'
                }} className="new3"></hr>
                <h1 className="yes">Yes! It's a crises.<br></br> We are here with you!</h1>
            </div>
<div style={{textAlign:'center'}}>
            <img src={covidweb} className="covidweb"></img>
            <img src={covidmob} className="covidmob"></img>
</div>
        </div>
    )
}
